import React, { useEffect } from 'react'
import WebFont from 'webfontloader';
import Moment from 'react-moment';
import moment from 'moment';
// eslint-disable-next-line
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { Container, Row, Card, Button, Table, Dropdown, Badge, Modal, Form, ListGroup, Col } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faArrowRight, faCoins, faEye, faGavel, faPlusCircle, faSchool, faSearch } from '@fortawesome/free-solid-svg-icons'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import es from 'date-fns/locale/es';
import NavBar from "../navbar/Navbar";
import { get, post } from '../../../controllers/endpoints.controller';
import { getUserToken, logout } from '../../../controllers/user.controller';
import { removeTildes } from '../../../tools/chains';
import { data } from 'jquery';



export default function NewCourtFileStep1() {

    const PAGE_TITLE = "Nuevo expediente judicial";
    const PAGE_ICON = faGavel;

    const [showSearchCustomerModal, setShowSearchCustomerModal] = React.useState(false);
    const [showSearchDefendantModal, setShowSearchDefendantModal] = React.useState(false);
    const [showLawerModal, setShowLawerModal] = React.useState(false);
    const [customers, setCustomers] = React.useState([]);
    const [selectedCustomer, setSelectedCustomer] = React.useState(null);
    const [defendants, setDefendants] = React.useState([]);
    const [selectedDefendant1, setSelectedDefendant1] = React.useState(null);
    const [selectedDefendant2, setSelectedDefendant2] = React.useState(null);
    const [selectedDefendant3, setSelectedDefendant3] = React.useState(null);
    const [actualDefendant, setActualDefendant] = React.useState(0);
    const [lawers, setLawers] = React.useState([]);
    const [selectedLawer, setSelectedLawer] = React.useState(0);
    const [defendantLawer, setDefendantLawer] = React.useState({});
    const [canNext, setCanNext] = React.useState(false);
    const [date, setDate] = React.useState(new Date());
    const [color, setColor] = React.useState("#0D72B5");

    const [showNewCustomerDefendantModal, setShowNewCustomerDefendantModal] = React.useState(false);
    const handleCloseNewCustomerDefendantModal = () => { setShowNewCustomerDefendantModal(false); };
    const handleShowNewCustomerDefendantModal = (isCustomer) => {
        handleCloseSearchCustomerModal();
        setSelectedIsCustomer(isCustomer);
        getProvinces();
        getGenders();
        setShowNewCustomerDefendantModal(true);
    };

    const [stepData, setStepData] = React.useState({});

    const [provinces, setProvinces] = React.useState([]);
    const [selectedProvince, setSelectedProvince] = React.useState("");
    const [genders, setGenders] = React.useState([]);
    const [selectedGenders, setSelectedGender] = React.useState(0);
    const [selectedIsBusiness, setSelectedIsBusiness] = React.useState(false);
    const [selectedIsCustomer, setSelectedIsCustomer] = React.useState(false);
    const [personTypes, setPersonTypes] = React.useState(["Persona física", "Persona jurídica"]);

    const handleProvince = (event) => {
        setSelectedProvince(event.target.value);
    };

    const handleGender = (event) => {
        setSelectedGender(event.target.value);
    };

    const handleColor = (event) => {
        setColor(event.target.value);
    };

    const handleIdentifier = (event) => {
        getIdentifier(event.target.value)
    };

    const handleType = (event) => {
        setSelectedIsBusiness(event.target.value == "Persona jurídica")
    };


    useEffect(() => {
        WebFont.load({
            google: {
                families: ['Roboto:100,300,400']
            }
        });

        document.title = `Demo - ${PAGE_TITLE}`;
        registerLocale('es', es);
        getLawers();
    }, []);

    const getIdentifier = async function (data) {
        const chain = {
            chain: data
        }
        const req = await post('/others/identifier', getUserToken(), chain);
        const res = await req.json();

        if (req.status === 200) {
            document.getElementById('textIdentifier').value = res.chain;
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
            console.log(res)
        }
    }

    const getProvinces = async function () {
        const req = await get('/others/provinces', getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            setProvinces(res);
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const getGenders = async function () {
        const req = await get('/others/genders', getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            setGenders(res);
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const handleDate = (event) => {
        setDate(event);
    };

    const handleCloseSearchCustomerModal = () => {
        setShowSearchCustomerModal(false);
    };

    const handleOpenSearchCustomerModal = () => {
        setShowSearchCustomerModal(true);
    };

    const handleCloseSearchDefendantModal = () => {
        setShowSearchDefendantModal(false);
    };

    const handleOpenSearchDefendantModal = (index) => {
        setActualDefendant(index)
        setShowSearchDefendantModal(true);
    };

    const handleCloseLawerModal = () => {
        setShowLawerModal(false);
    };

    const handleProcessLawerModal = () => {
        processDefendantLawer()
        setShowLawerModal(false);
    };

    const handleOpenLawerModal = () => {
        setShowLawerModal(true);
    };

    const handleCustomerSelected = (index, isBusiness) => {
        for (let c of customers) {
            if (c.id == index && c.isBusiness == isBusiness) {
                setSelectedCustomer(c);
                document.getElementById('textCustomer').value = c.isBusiness == true ? (`${c.cuit.length > 1 ? `CUIT ${c.cuit} - ` : ""}${c.legalName}`) : `${c.dni.length > 1 ? `DNI ${c.dni} - ` : ""}${c.surname.toUpperCase()}, ${c.name}`;
                setCustomers([]);
                handleCloseSearchCustomerModal();
                setCanNext(checkStep(false, true, false));
            }
        }
    };

    const checkStep = (employee, customer, defendant) => {
        if (employee) {
            if (selectedCustomer) {

                return true;

            }
        } else if (customer) {
            if (selectedLawer) {

                return true;

            }
        }
        return false;
    }

    const handleDefendantSelected = (index, isBusiness) => {
        for (let d of defendants) {
            if (d.id == index && d.isBusiness == isBusiness) {
                switch (actualDefendant) {
                    case 1:
                        setSelectedDefendant1(d);
                        break;
                    case 2:
                        setSelectedDefendant2(d);
                        break;
                    case 3:
                        setSelectedDefendant3(d);
                        break;
                }
                document.getElementById(`textDefendant${actualDefendant}`).value = d.isBusiness == true ? (`${d.cuit.length > 1 ? `CUIT ${d.cuit} - ` : ""}${d.legalName}`) : `${d.dni.length > 1 ? `DNI ${d.dni} - ` : ""}${d.surname.toUpperCase()}, ${d.name}`;
                setDefendants([]);
                handleCloseSearchDefendantModal();
                //setCanNext(checkStep(false, false, true));
            }
        }
    };

    const handleSearchCustomers = async (event) => {
        if (event.target.value.length > 2) {
            const chain = {
                chain: event.target.value
            };
            const req = await post(`/customers/search`, getUserToken(), chain);
            const res = await req.json();

            if (req.status === 200) {
                setCustomers(res)
            } else {
                alert(res.message);
                console.log(res)
            }
        } else {
            setCustomers([])
        }
    };

    const handleSearchDefendants = async (event) => {
        if (event.target.value.length > 2) {
            const chain = {
                chain: event.target.value
            };
            const req = await post(`/defendants/search`, getUserToken(), chain);
            const res = await req.json();

            if (req.status === 200) {
                setDefendants(res)
            } else {
                alert(res.message);
                console.log(res)
            }
        } else {
            setDefendants([])
        }
    };

    const handleLawer = (event) => {
        setSelectedLawer(event.target.value);
        if (event.target.value != "0") {
            setCanNext(checkStep(true, false, false));
        }
    };

    const getLawers = async function () {
        const req = await get(`/users/lawers`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            setLawers(res)
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const processDefendantLawer = () => {
        const l = {
            name: document.getElementById('lawerName').value,
            surname: document.getElementById('lawerSurname').value,
            dni: document.getElementById('lawerDNI').value,
            phone: document.getElementById('lawerPhone').value,
            email: document.getElementById('lawerEmail').value,
            address: document.getElementById('lawerAddress').value,
            city: document.getElementById('lawerCity').value,
            province: document.getElementById('lawerProvince').value,
            postalCode: document.getElementById('lawerPostalCode').value,
            firm: document.getElementById('lawerFirm').value
        };
        setDefendantLawer(l);
        document.getElementById('textDefendantLawer').value = `${document.getElementById('lawerSurname').value}, ${document.getElementById('lawerName').value}`
    }

    const createCustomerDefendant = () => {
        handleCloseNewCustomerDefendantModal();
        if (selectedIsBusiness) {
            createBusiness(selectedIsCustomer);
        } else {
            createPerson(selectedIsCustomer);
        }
    }

    const createPerson = async function (isCustomer) {
        const data = {
            dni: document.getElementById('textDNI').value,
            personTypeId: null,
            personSexId: selectedGenders == "0" ? null : selectedGenders,
            familyId: null,
            name: document.getElementById('textName').value,
            surname: document.getElementById('textSurname').value,
            phone: document.getElementById('textPhone').value,
            mobile: document.getElementById('textMobile').value,
            email: document.getElementById('textEmail').value,
            isWorking: document.getElementById('textJob').value.length > 1 ? true : false,
            placeWork: document.getElementById('textJob').value,
            address: document.getElementById('textAddress').value,
            city: document.getElementById('textCity').value,
            province: selectedProvince,
            postalCode: document.getElementById('textPostalCode').value
        }

        const req = await post(`/${isCustomer ? 'customers' : 'defendants'}/persons`, getUserToken(), data);
        const res = await req.json();

        if (req.status === 201) {
            //Todo
            if (isCustomer) {
                setSelectedCustomer(res);

                document.getElementById('textCustomer').value = `${res.dni.length > 1 ? `DNI ${res.dni} - ` : ""}${res.surname.toUpperCase()}, ${res.name}`;
                setCustomers([]);
                setCanNext(checkStep(false, true, false));
                setSelectedIsBusiness(false)
            } else {
                switch (actualDefendant) {
                    case 1:
                        setSelectedDefendant1(res);
                        break;
                    case 2:
                        setSelectedDefendant2(res);
                        break;
                    case 3:
                        setSelectedDefendant3(res);
                        break;
                }
                document.getElementById(`textDefendant${actualDefendant}`).value = `${res.dni.length > 1 ? `DNI ${res.dni} - ` : ""}${res.surname.toUpperCase()}, ${res.name}`;
                setDefendants([]);
                handleCloseSearchDefendantModal();
                setSelectedIsBusiness(false)
            }



        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const createBusiness = async function (isCustomer) {
        const data = {
            cuit: document.getElementById('textCuit').value,
            legalName: document.getElementById('textLegalName').value,
            address: document.getElementById('textAddress').value,
            city: document.getElementById('textCity').value,
            province: selectedProvince,
            postalCode: document.getElementById('textPostalCode').value,
            order: document.getElementById('textOrder').value.length > 1 ? document.getElementById('textOrder').value : null,
            identifier: document.getElementById('textIdentifier').value,
            color: color,
        }

        const req = await post(`/${isCustomer ? 'customers' : 'defendants'}/business`, getUserToken(), data);
        const res = await req.json();

        if (req.status === 201) {
            if (isCustomer) {
                setSelectedCustomer(res);

                document.getElementById('textCustomer').value = (`${res.cuit.length > 1 ? `CUIT ${res.cuit} - ` : ""}${res.legalName}`);
                setCustomers([]);
                setCanNext(checkStep(false, true, false));
                setSelectedIsBusiness(false)
            } else {
                switch (actualDefendant) {
                    case 1:
                        setSelectedDefendant1(res);
                        break;
                    case 2:
                        setSelectedDefendant2(res);
                        break;
                    case 3:
                        setSelectedDefendant3(res);
                        break;
                }
                document.getElementById(`textDefendant${actualDefendant}`).value = (`${res.cuit.length > 1 ? `CUIT ${res.cuit} - ` : ""}${res.legalName}`);
                setDefendants([]);
                handleCloseSearchDefendantModal();
                setSelectedIsBusiness(false)
            }



        } else if (req.status === 403) {
            logout();
        } else {
            console.log(res)
            alert(res.message);
        }
    }

    return (
        <div className='p-0' style={{ fontFamily: 'Roboto' }}>
            <style>{'body {background-color: #EBEBEB !important;}'}</style>
            <NavBar />

            <div style={{ height: 300, background: '#FAFAFA' }}>
                <Container>
                    <Row style={{ paddingTop: 10 }}>
                        <table className='w-75' style={{ marginLeft: 'auto', marginRight: 'auto' }}>
                            <tr>
                                <td style={{ fontSize: 45, fontWeight: 300 }}>
                                    <span style={{ color: '#A0A0A0' }}>{PAGE_TITLE}</span>
                                </td>
                                <td style={{ fontSize: 140, width: 150 }}>
                                    <FontAwesomeIcon icon={PAGE_ICON} style={{ fontSize: 140, color: "A0A0A0" }} />
                                </td>
                            </tr>
                        </table>
                    </Row>
                </Container>
            </div>

            <Container>
                <Row style={{ marginTop: -70 }}>
                    <Card className='cards content mb-5'>
                        <table className='content-options content'>
                            <tr>
                                <td className='content-options rows left'>
                                    <Link to={'/expedientes'} className='link-primary'>
                                        <FontAwesomeIcon icon={faArrowLeft} style={{ marginRight: 8 }} />Volver a Judicial
                                    </Link>
                                    <span className='ms-3' style={{ color: '#A0A0A0' }}>|</span><span className='ms-3'>Datos generales del expediente</span>

                                </td>
                                <td className='content-options rows right'>

                                </td>
                            </tr>
                        </table>

                        <Container className='mt-4 mb-4'>
                            <Form>
                                <table className='w-100'>
                                    <tr>
                                        <td style={{ width: 130, fontWeight: 'bold' }}>Fecha de inicio</td>
                                        <td className=''>
                                            <DatePicker className='form-control cover content rows element' locale="es" id='' dateFormat="dd/MM/yyyy" selected={date} onChange={(d) => handleDate(d)} />
                                        </td>
                                        <td className='ps-2' style={{ width: 30 }}>

                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='pt-4'>Responsable</td>
                                        <td className='pt-4'>
                                            <Form.Select className='cover content rows element' id="" onChange={(event) => handleLawer(event)}>
                                                <option value="0">Seleccionar...</option>
                                                {lawers.map((p, i) => (
                                                    <option key={i} id={i} value={p.id}>{p.name} {p.surname}</option>
                                                ))}
                                            </Form.Select>
                                        </td>
                                        <td className='ps-2' style={{ width: 30 }}>

                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='pt-4' style={{ fontWeight: 'bold' }}>Cliente</td>
                                        <td className='pt-4'>
                                            <Form.Group>
                                                <Form.Control type="text" id="textCustomer" disabled placeholder="" />
                                            </Form.Group>
                                        </td>
                                        <td className='pt-4 ps-2' style={{ width: 30 }}>
                                            <Button variant="primary" onClick={handleOpenSearchCustomerModal}><FontAwesomeIcon icon={faSearch} /></Button>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='pt-4' style={{ fontWeight: 'bold' }}>Contrarios</td>
                                        <td className='pt-4'>
                                            <Form.Group>
                                                <Form.Control type="text" id="textDefendant1" disabled placeholder="" />
                                                <Form.Control className='mt-2' type="text" id="textDefendant2" disabled placeholder="" />
                                                <Form.Control className='mt-2' type="text" id="textDefendant3" disabled placeholder="" />
                                            </Form.Group>
                                        </td>
                                        <td className='ps-2 pt-4' style={{ width: 30 }}>
                                            <Button variant="primary" onClick={() => handleOpenSearchDefendantModal(1)}><FontAwesomeIcon icon={faSearch} /></Button>
                                            <Button variant="primary" className='mt-2' onClick={() => handleOpenSearchDefendantModal(2)}><FontAwesomeIcon icon={faSearch} /></Button>
                                            <Button variant="primary" className='mt-2' onClick={() => handleOpenSearchDefendantModal(3)}><FontAwesomeIcon icon={faSearch} /></Button>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='pt-4' style={{ fontWeight: 'bold' }}>Abogado contrario</td>
                                        <td className='pt-4'>
                                            <Form.Group>
                                                <Form.Control type="text" id="textDefendantLawer" disabled placeholder="" />
                                            </Form.Group>
                                        </td>
                                        <td className='ps-2 pt-4' style={{ width: 30 }}>
                                            <Button variant="primary" onClick={() => handleOpenLawerModal()}><FontAwesomeIcon icon={faSearch} /></Button>
                                        </td>
                                    </tr>
                                </table>
                            </Form>

                            <div className='mt-5' style={{ textAlign: 'right' }}>
                                {canNext ?
                                    <Link to={`/judicial/nueva/2`} state={
                                        {
                                            customer: selectedCustomer,
                                            defendant1: selectedDefendant1,
                                            defendant2: selectedDefendant2,
                                            defendant3: selectedDefendant3,
                                            userId: selectedLawer,
                                            defendantLawer: defendantLawer,
                                            date: date
                                        }
                                    }>
                                        <Button variant="success" disabled={false}>Siguiente<FontAwesomeIcon icon={faArrowRight} style={{ marginLeft: 8 }} /></Button>
                                    </Link>
                                    :
                                    <Button variant="success" disabled={true}>Siguiente<FontAwesomeIcon icon={faArrowRight} style={{ marginLeft: 8 }} /></Button>
                                }

                            </div>

                        </Container>
                    </Card>
                </Row>
            </Container>


            <Modal show={showSearchCustomerModal} size='lg' onHide={handleCloseSearchCustomerModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Buscar cliente</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Control type="text" id="" autoFocus placeholder="Buscar..." onChange={(event) => handleSearchCustomers(event)} />
                    {customers.length > 0 ? <p className='mt-4 mb-0'>Resultado de búsqueda:</p> : <span></span>}
                    <ListGroup className='mt-2'>
                        {customers.map((c, i) => (
                            <ListGroup.Item key={i} onClick={() => handleCustomerSelected(c.id, c.isBusiness)}>{c.isBusiness == true ? (<div><p className='m-0' style={{ fontSize: 12 }}>Persona jurídica</p><p className='m-0'></p>{c.cuit.length > 1 ? `CUIT ${c.cuit} - ` : ""}{c.legalName}</div>) : (<div><p className='m-0' style={{ fontSize: 12 }}>Persona física</p><p className='m-0'></p>{c.dni.length > 1 ? `DNI ${c.dni} - ` : ""}{c.surname.toUpperCase()}, {c.name}</div>)}</ListGroup.Item>
                        ))}
                    </ListGroup>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseSearchCustomerModal}>
                        Cerrar
                    </Button>
                    <Button variant="primary" onClick={() => handleShowNewCustomerDefendantModal(true)}>
                        Crear nuevo cliente
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showSearchDefendantModal} size='lg' onHide={handleCloseSearchDefendantModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Buscar contrario</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Control type="text" id="" autoFocus placeholder="Buscar..." onChange={(event) => handleSearchDefendants(event)} />
                    {defendants.length > 0 ? <p className='mt-4 mb-0'>Resultado de búsqueda:</p> : <span></span>}
                    <ListGroup className='mt-2'>
                        {defendants.map((c, i) => (
                            <ListGroup.Item key={i} onClick={() => handleDefendantSelected(c.id, c.isBusiness)}>{c.isBusiness == true ? (<div><p className='m-0' style={{ fontSize: 12 }}>Persona jurídica</p><p className='m-0'></p>{c.cuit.length > 1 ? `CUIT ${c.cuit} - ` : ""}{c.legalName}</div>) : (<div><p className='m-0' style={{ fontSize: 12 }}>Persona física</p><p className='m-0'></p>DNI {c.dni} - {c.surname.toUpperCase()}, {c.name}</div>)}</ListGroup.Item>
                        ))}
                    </ListGroup>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseSearchDefendantModal}>
                        Cerrar
                    </Button>
                    <Button variant="primary" onClick={() => handleShowNewCustomerDefendantModal(false)}>
                        Crear nuevo contrario
                    </Button>
                </Modal.Footer>
            </Modal>


            <Modal show={showLawerModal} onHide={handleCloseLawerModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Abogado contrario</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group className=''>
                        <Form.Text>Nombre</Form.Text>
                        <Form.Control type="text" id="lawerName" placeholder="" />
                    </Form.Group>
                    <Form.Group className='mt-2'>
                        <Form.Text>Apellido</Form.Text>
                        <Form.Control type="text" id="lawerSurname" placeholder="" />
                    </Form.Group>
                    <Form.Group className='mt-2'>
                        <Form.Text>DNI</Form.Text>
                        <Form.Control type="text" id="lawerDNI" placeholder="" />
                    </Form.Group>
                    <Form.Group className='mt-2'>
                        <Form.Text>Estudio</Form.Text>
                        <Form.Control type="text" id="lawerFirm" placeholder="" />
                    </Form.Group>
                    <Form.Group className='mt-2'>
                        <Form.Text>Teléfono / Celular</Form.Text>
                        <Form.Control type="text" id="lawerPhone" placeholder="" />
                    </Form.Group>
                    <Form.Group className='mt-2'>
                        <Form.Text>Correo electrónico</Form.Text>
                        <Form.Control type="text" id="lawerEmail" placeholder="" />
                    </Form.Group>
                    <Form.Group className='mt-2'>
                        <Form.Text>Dirección</Form.Text>
                        <Form.Control type="text" id="lawerAddress" placeholder="" />
                    </Form.Group>
                    <Form.Group className='mt-2'>
                        <Form.Text>Localidad</Form.Text>
                        <Form.Control type="text" id="lawerCity" placeholder="" />
                    </Form.Group>
                    <Form.Group className='mt-2'>
                        <Form.Text>Provincia</Form.Text>
                        <Form.Control type="text" id="lawerProvince" placeholder="" />
                    </Form.Group>
                    <Form.Group className='mt-2'>
                        <Form.Text>Código postal</Form.Text>
                        <Form.Control type="text" id="lawerPostalCode" placeholder="" />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseLawerModal}>
                        Cerrar
                    </Button>
                    <Button variant="primary" onClick={handleProcessLawerModal}>
                        Confirmar
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showNewCustomerDefendantModal} size='xl' onHide={handleCloseNewCustomerDefendantModal}>
                <Modal.Header closeButton>
                    <Modal.Title>{selectedIsCustomer ? "Nuevo cliente" : "Nuevo contrario"}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row className='mb-4'>
                        <Col>
                            <table className='w-50'>
                                <tr>
                                    <td className='pt-2' style={{ width: 170 }}>Tipo</td>
                                    <td className='pt-2'>
                                        <Form.Select className='cover content rows element' id="" onChange={(event) => handleType(event)}>
                                            {personTypes.map((p, i) => (
                                                <option key={i} id={i} value={p}>{p}</option>
                                            ))}
                                        </Form.Select>
                                    </td>
                                </tr>
                            </table>
                        </Col>
                    </Row>

                    {selectedIsBusiness ?
                        <Row>
                            <Col xs={6}>
                                <table className='w-100'>
                                    <tr>
                                        <td className='pt-2' style={{ width: 170 }}>Razón social</td>
                                        <td className='pt-2'>
                                            <Form.Group>
                                                <Form.Control type="text" id="textLegalName" placeholder="" onChange = {(event) => handleIdentifier(event)}/>
                                            </Form.Group>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='pt-2' style={{ width: 170 }}>CUIT</td>
                                        <td className='pt-2'>
                                            <Form.Group>
                                                <Form.Control type="text" id="textCuit" placeholder="" />
                                            </Form.Group>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='pt-2' >Orden</td>
                                        <td className='pt-2'>
                                            <Form.Group>
                                                <Form.Control type="text" id="textOrder" placeholder="" />
                                            </Form.Group>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='pt-2' >Identificador</td>
                                        <td className='pt-2' >
                                            <Row>
                                                <Col>
                                                    <Form.Control type="text" id="textIdentifier" placeholder="" />
                                                </Col>
                                                <Col>
                                                    <Form.Control
                                                        type="color"
                                                        id="exampleColorInput"
                                                        defaultValue="#0D72B5"
                                                        title="Choose your color"
                                                        onChange={(event) => handleColor(event)}
                                                    />
                                                </Col>
                                            </Row>
                                        </td>
                                    </tr>


                                </table>
                            </Col>
                            <Col xs={6}>
                                <table className='w-100'>

                                    <tr>
                                        <td className='pt-2' style={{ width: 170 }} >Dirección</td>
                                        <td className='pt-2'>
                                            <Form.Group>
                                                <Form.Control type="text" id="textAddress" placeholder="" />
                                            </Form.Group>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='pt-2' >Localidad</td>
                                        <td className='pt-2'>
                                            <Form.Group>
                                                <Form.Control type="text" id="textCity" placeholder="" />
                                            </Form.Group>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='pt-2' >Provincia</td>
                                        <td className='pt-2'>
                                            <Form.Group>
                                                <Form.Select className='cover content rows element' id="" onChange={(event) => handleProvince(event)}>
                                                    <option value="0">Seleccionar...</option>
                                                    {provinces.map((p, i) => (
                                                        <option key={i} id={i} value={p.name}>{p.name}</option>
                                                    ))}
                                                </Form.Select>
                                            </Form.Group>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='pt-2' >Código postal</td>
                                        <td className='pt-2'>
                                            <Form.Group>
                                                <Form.Control type="text" id="textPostalCode" placeholder="" />
                                            </Form.Group>
                                        </td>
                                    </tr>
                                </table>
                            </Col>
                        </Row>
                        :
                        <Row>
                            <Col xs={6}>
                                <table className='w-100'>
                                    <tr>
                                        <td className='pt-2' style={{ width: 170 }}>Nombre</td>
                                        <td className='pt-2'>
                                            <Form.Group>
                                                <Form.Control type="text" id="textName" placeholder="" />
                                            </Form.Group>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='pt-2'>Apellido</td>
                                        <td className='pt-2'>
                                            <Form.Group>
                                                <Form.Control type="text" id="textSurname" placeholder="" />
                                            </Form.Group>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='pt-2'>DNI</td>
                                        <td className='pt-2'>
                                            <Form.Group>
                                                <Form.Control type="text" id="textDNI" placeholder="" />
                                            </Form.Group>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='pt-2'>Sexo</td>
                                        <td className='pt-2'>
                                            <Form.Group>
                                                <Form.Select className='cover content rows element' id="" onChange={(event) => handleGender(event)}>
                                                    <option value="0">Seleccionar...</option>
                                                    {genders.map((p, i) => (
                                                        <option key={i} id={i} value={p.id}>{p.name}</option>
                                                    ))}
                                                </Form.Select>
                                            </Form.Group>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='pt-2'>Teléfono</td>
                                        <td className='pt-2'>
                                            <Form.Group>
                                                <Form.Control type="text" id="textPhone" placeholder="" />
                                            </Form.Group>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='pt-2'>Celular</td>
                                        <td className='pt-2'>
                                            <Form.Group>
                                                <Form.Control type="text" id="textMobile" placeholder="" />
                                            </Form.Group>
                                        </td>
                                    </tr>

                                </table>
                            </Col>
                            <Col xs={6}>
                                <table className='w-100'>

                                    <tr>
                                        <td className='pt-2' style={{ width: 170 }}>Correo electrónico</td>
                                        <td className='pt-2'>
                                            <Form.Group>
                                                <Form.Control type="text" id="textEmail" placeholder="" />
                                            </Form.Group>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='pt-2'>Trabajo</td>
                                        <td className='pt-2'>
                                            <Form.Group>
                                                <Form.Control type="text" id="textJob" placeholder="" />
                                            </Form.Group>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='pt-2'>Dirección</td>
                                        <td className='pt-2'>
                                            <Form.Group>
                                                <Form.Control type="text" id="textAddress" placeholder="" />
                                            </Form.Group>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='pt-2'>Localidad</td>
                                        <td className='pt-2'>
                                            <Form.Group>
                                                <Form.Control type="text" id="textCity" placeholder="" />
                                            </Form.Group>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='pt-2'>Provincia</td>
                                        <td className='pt-2'>
                                            <Form.Group>
                                                <Form.Select className='cover content rows element' id="" onChange={(event) => handleProvince(event)}>
                                                    <option value="0">Seleccionar...</option>
                                                    {provinces.map((p, i) => (
                                                        <option key={i} id={i} value={p.name}>{p.name}</option>
                                                    ))}
                                                </Form.Select>
                                            </Form.Group>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='pt-2'>Código postal</td>
                                        <td className='pt-2'>
                                            <Form.Group>
                                                <Form.Control type="text" id="textPostalCode" placeholder="" />
                                            </Form.Group>
                                        </td>
                                    </tr>
                                </table>
                            </Col>
                        </Row>
                    }

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseNewCustomerDefendantModal}>
                        Cerrar
                    </Button>
                    <Button variant="primary" onClick={createCustomerDefendant}>
                        Confirmar
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}