import React, { useEffect } from 'react'
import WebFont from 'webfontloader';
import Moment from 'react-moment';
// eslint-disable-next-line
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { Container, Row, Card, Button, Col, Dropdown, Badge, Modal, Form, Spinner, Accordion, Alert, Tooltip, OverlayTrigger, ToggleButton, Table } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faFileExcel, faFileWord, faCheck, faCoins, faEllipsisV, faCommentAlt, faArrowLeft, faPlusCircle, faDownload, faTrash, faTasks, faAddressBook, faPhoneAlt, faEnvelope, faMapMarkerAlt, faUser, faIdCard, faInfoCircle, faWallet, faShare, faRefresh } from '@fortawesome/free-solid-svg-icons'
import NavBar from "../navbar/Navbar";
import { get, post, put, del } from '../../../controllers/endpoints.controller';
import { getUserToken, logout } from '../../../controllers/user.controller';
import { } from '@fortawesome/free-regular-svg-icons';
import { pn } from '../../../tools/chains';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import es from 'date-fns/locale/es';
import { triggerBase64Download } from 'common-base64-downloader-react';
import { convert2Base64, processDate, timeDue, getFileIcon, timeDueWithBadge } from '../../../tools/tools';
import { params } from '../../../tools/constants'
import moment from 'moment';

export default function DebtDetails() {

    const PAGE_TITLE = "GDD";
    const PAGE_ICON = faCoins;
    const [error, setError] = React.useState(<div></div>);
    const [tasks, setTasks] = React.useState([]);
    const [students, setStudents] = React.useState([]);
    const [debt, setDebt] = React.useState({});
    const [lastDueQty, setLastDueQty] = React.useState(0);
    const [customer, setCustomer] = React.useState(null);
    const [defendants, setDefendants] = React.useState([]);
    const [isArchived, setIsArchived] = React.useState(false);
    const [familyFather, setFamilyFather] = React.useState({});
    const [familyMother, setFamilyMother] = React.useState({});
    const [familyStudents, setFamilyStudents] = React.useState([]);
    const [familyDebtors, setFamilyDebtors] = React.useState([]);
    const [dueColumns, setDueColumns] = React.useState([]);
    const [dues, setDues] = React.useState([]);
    const [debtDetailsColumns, setDebtDetailsColumns] = React.useState([]);
    const [debtDetails, setDebtDetails] = React.useState([]);
    const [proceedingActionsColumns, setProceedingActionsColumns] = React.useState([]);
    const [proceedingActions, setProceedingActions] = React.useState([]);
    const [customerContactsHeader, setCustomerContactsHeader] = React.useState([]);
    const [customerContacts, setCustomerContacts] = React.useState([]);
    const [selectedCustomerContacts, setSelectedCustomerContacts] = React.useState({});
    const [lawers, setLawers] = React.useState([]);
    const [selectedLawer, setSelectedLawer] = React.useState({});
    const [selectedDebtor, setSelectedDebtor] = React.useState({});
    const [preAgreementButton, setPreAgreementButton] = React.useState(<span></span>);
    const [showPreAgreementModal, setShowPreAgreementModal] = React.useState(false);
    const handleClosePreAgreementModal = () => { setShowPreAgreementModal(false); };
    const handleShowPreAgreementModal = () => {
        setPreAgreementButton(<span><FontAwesomeIcon icon={faDownload} className='me-1'></FontAwesomeIcon>Descargar</span>)
        getLawers();
        setShowPreAgreementModal(true);
    };
    const [preAgreementDate, setPreAgreementDate] = React.useState(new Date());
    const [showNewActionModal, setShowNewActionModal] = React.useState(false);
    const [actionCategories, setActionCategories] = React.useState([]);
    const [selectedUser, setSelectedUser] = React.useState({});
    const [selectedActionCategory, setSelectedActionCategory] = React.useState({});
    const handleCloseNewActionModal = () => { setShowNewActionModal(false); };
    const handleShowNewActionModal = () => {
        setNewActionDate(new Date())
        setError()
        setSelectedLawer("0")
        setSelectedActionCategory("0")
        setNewActionFile();
        getActionCategories();
        getLawers();
        setShowNewActionModal(true);
    };

    const [showDeleteActionModal, setShowDeleteActionModal] = React.useState(false);
    const [selectedActionId, setSelectedActionId] = React.useState("0");
    const [selectedActionDescription, setSelectedActionDescription] = React.useState("");
    const handleCloseDeleteActionModal = () => { setShowDeleteActionModal(false); };
    const handleShowDeleteActionModal = (id, description) => {
        setSelectedActionId(id)
        setSelectedActionDescription(description)
        setShowDeleteActionModal(true);
    }

    const [showDeleteProceedingModal, setShowDeleteProceedingModal] = React.useState(false);
    const handleCloseDeleteProceedingModal = () => { setShowDeleteProceedingModal(false); };
    const handleShowDeleteProceedingModal = () => {
        setShowDeleteProceedingModal(true);
    }

    const [showCalcDuesModal, setShowCalcDuesModal] = React.useState(false);
    const handleCloseCalcDuesModal = () => { setShowCalcDuesModal(false); };
    const handleShowCalcDuesModal = () => {
        setShowCalcDuesModal(true);
    }

    const [newActionFile, setNewActionFile] = React.useState(null);
    const [newActionDate, setNewActionDate] = React.useState(new Date());
    const [showNewTaskModal, setShowNewTaskModal] = React.useState(false);
    const [reminderOptions, setReminderOptions] = React.useState([]);
    const [newTaskDate, setNewTaskDate] = React.useState(null);
    const [newTaskReminder, setNewTaskReminder] = React.useState({});
    const [newTaskUser, setNewTaskUser] = React.useState({});
    const [newTaskNotificationChecked, setNewTaskNotificationChecked] = React.useState(false);
    const handleCloseNewTaskModal = () => { setShowNewTaskModal(false); };
    const handleShowNewTaskModal = () => {
        getReminderOptions();
        getLawers();
        setShowNewTaskModal(true);
    };

    const handlePreAgreementDate = (event) => {
        setPreAgreementDate(event);
    };

    const [showChangeModuleModal, setShowChangeModuleModal] = React.useState(false);
    const [selectedModule, setSelectedModule] = React.useState("");
    const handleCloseChangeModuleModal = () => { setShowChangeModuleModal(false); };
    const handleShowChangeModuleModal = () => {
        setShowChangeModuleModal(true);
    };

    const handleSelectedModule = (event) => {
        setSelectedModule(event.target.value);
    };

    const handlePreAgreementApoderadoLegalNombre = (event) => {
        let dni = "";
        for (let cc of customerContacts) {
            if (event.target.value == cc.id) {
                setSelectedCustomerContacts(cc);
                for (let cd of cc.ContactData) {
                    if (cd.param == "DNIN") {
                        dni = cd.data;
                    }
                }
            }
        }
        document.getElementById('preagreementApoderadoLegalDNI').value = dni;
    };

    const handleNewActionDate = (event) => {
        setNewActionDate(event);
    };

    const handleNewActionFile = async (event) => {
        const file = event.target.files[0]
        setNewActionFile(file)
    }

    const handlePreAgreementLawer = (event) => {
        setSelectedLawer(event.target.value)
    };

    const handlePreAgreementDebtor = (event) => {
        setSelectedDebtor(event.target.value)
    };

    const handleNewActionUser = (event) => {
        setSelectedLawer(event.target.value)
    };

    const handleNewActionCategory = (event) => {
        setSelectedActionCategory(event.target.value)
    };

    const handleNewTaskUser = (event) => {
        setNewTaskUser(event.target.value)
    };

    const handleNewTaskReminder = (event) => {
        setNewTaskReminder(event.target.value)
    };

    const handleNewTaskDate = (event) => {
        setNewTaskDate(event);
    };

    const handleNewTaskNotification = (event) => {
        setNewTaskNotificationChecked(event)
    };

    useEffect(() => {
        WebFont.load({
            google: {
                families: ['Roboto:100,300,400']
            }
        });
        registerLocale('es', es);
        document.title = `EAC - ${PAGE_TITLE}`;

        getDebtById(getProceedingId());
        getTasks();
    }, []);

    const getProceedingId = function () {
        const params = new URLSearchParams(window.location.search);
        if (params.has('id')) {
            return params.get('id');
        } else {
            return -1;
        }
    }

    const getDebtById = async function (id) {
        const req = await get(`/proceedings/debts/byId/${id}`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            //console.log(res)
            setDebt(res.Debt);
            if (res.ProceedingCustomer.Business) {
                if (res.ProceedingCustomer.Business.code) {
                    res.ProceedingCustomer.Business.code = <Badge bg='' className='ms-1 ' style={{ backgroundColor: `${res.ProceedingCustomer.Business.color}` }}>{res.ProceedingCustomer.Business.code}</Badge>;
                }
            }
            setCustomer(res.ProceedingCustomer)
            getCustomerContacts(res.ProceedingCustomer.businessId);
            //setFamily(res.debt.family);
            //parseFamily(res.debt.family);
            document.title = `EAC - ${PAGE_TITLE} ${res.Debt.name}`;
            setDueColumns(['#', 'Capital', 'Intereses', 'Honorarios', 'Total']);
            let dues = [];
            /*for(let d of res.Debt.Dues){
                d.statusName = <Badge bg='' className='content-table status' style={{backgroundColor: `${d.debt.status.color}`}}>{d.debt.status.name}</Badge>;
                d.action = <Button variant="success"><FontAwesomeIcon icon={faCheck} /></Button>;
                dues.push(d);
            }*/
            setDues(dues);

            setIsArchived(res.isArchived);

            setDebtDetailsColumns(['Alumno', 'Fecha', 'Importe', 'Días', 'Intereses', 'Total', 'Referencia']);
            let debtDetails = [];
            for (let dd of res.Debt.DebtDetails) {
                //d.action = <Button className='buttonBlue' variant="primary"><FontAwesomeIcon icon={faEye} /></Button>;
                dd.studentName = `${dd.student}`
                debtDetails.push(dd);
            }
            setDebtDetails(debtDetails);

            setProceedingActionsColumns(['Fecha', 'Categoría', 'Tarea', 'Foja', '', 'Encargado', '']);
            let pa = [];
            for (let dad of res.Actions) {
                //d.action = <Button className='buttonBlue' variant="primary"><FontAwesomeIcon icon={faEye} /></Button>;
                dad.action = (
                    <Dropdown>
                        <Dropdown.Toggle variant="light" id="dropdown-basic">
                            <span style={{ marginRight: 10 }}><FontAwesomeIcon icon={faEllipsisV} /></span>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            {dad.file != null ? <Dropdown.Item onClick={() => downloadFile(dad.file.id, dad.file.name)}><FontAwesomeIcon icon={faDownload} style={{ marginRight: 8, marginLeft: 2 }} />Descargar archivo</Dropdown.Item> : <div></div>}
                            <Dropdown.Item onClick={() => handleShowDeleteActionModal(dad.id, dad.description)}><FontAwesomeIcon icon={faTrash} style={{ marginRight: 10, marginLeft: 2 }} />Eliminar</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                )
                dad.fileIcon = dad.file != null ? (
                    <OverlayTrigger
                        placement="bottom"
                        overlay={<Tooltip id="button-tooltip-2">{dad.file.name}</Tooltip>}>

                        <span><FontAwesomeIcon style={{ fontSize: 30 }} icon={getFileIcon(dad.file.url)}></FontAwesomeIcon></span>
                    </OverlayTrigger>


                ) : <div></div>
                dad.category = <Badge bg='' className='content-table status' style={{ backgroundColor: `${dad.actionCategory.color}` }}>{dad.actionCategory.name}</Badge>;
                dad.createdBy = `${dad.User.name} ${(dad.User.surname).substring(0, 1)}.`;
                pa.push(dad);
            }
            setProceedingActions(pa);

            let defs = [];
            for (let def of res.ProceedingDefendants) {
                if (def.businessId == null && def.personId == null) {

                } else {
                    defs.push(def)
                }
            }
            setDefendants(defs)

            let s = [];
            for (let st of res.Debt.Students) {
                s.push(st.Person)
            }
            setStudents(s)

        } else {
            alert(res.message);
        }
    }

    const processContacts = function (data) {
        let result = [];
        let aux;
        let icon;

        for (let c of data) {
            aux = null;

            for (let i = 0; i < c.ContactData.length; i++) {
                switch (c.ContactData[i].param) {
                    case "PHON":
                        icon = <FontAwesomeIcon icon={faPhoneAlt}></FontAwesomeIcon>;
                        break;
                    case "EMAI":
                        icon = <FontAwesomeIcon icon={faEnvelope}></FontAwesomeIcon>;
                        break;
                    case "ADDR":
                        icon = <FontAwesomeIcon icon={faMapMarkerAlt}></FontAwesomeIcon>;
                        break;
                    case "POSI":
                        icon = <FontAwesomeIcon icon={faUser}></FontAwesomeIcon>;
                        break;
                    case "DNIN":
                        icon = <FontAwesomeIcon icon={faIdCard}></FontAwesomeIcon>;
                        break;
                    case "OTHE":
                        icon = <FontAwesomeIcon icon={faInfoCircle}></FontAwesomeIcon>;
                        break;
                    default:
                        break;
                }

                if (i === 0) {
                    aux = <span>{icon} {c.ContactData[i].data}</span>;
                } else {
                    aux = <span>{aux} | {icon} {c.ContactData[i].data}</span>;
                }
            }
            c.data = aux;
            //c.action = <span><Button variant="success" onClick={() => handleShowNewContactData(c.id)}><FontAwesomeIcon icon={faAddressCard} style={{marginRight: 8}}></FontAwesomeIcon>Añadir</Button><Button className='ms-1' variant="danger" onClick={() => handleShowSureDelete(c.name, c.id)}><FontAwesomeIcon icon={faTrash}></FontAwesomeIcon></Button></span>
            result.push(c);
        }
        setCustomerContacts(result);
    }

    const getCustomerContacts = async function (id) {
        const req = await get(`/customers/business/contacts/${id}`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            processContacts(res)
        } else {
            alert(res.message);
        }
    }

    const getLawers = async function () {
        const req = await get(`/users/lawers`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            setLawers(res)
        } else {
            alert(res.message);
        }
    }

    const getReminderOptions = async function () {
        const req = await get(`/tasks/reminderOptions/`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            setReminderOptions(res)
        } else {
            alert(res.message);
        }
    }

    const getTasks = async function () {
        const req = await get(`/tasks/byProceedingId/${getProceedingId()}`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            console.log(res)
            setTasks(res)
        } else {
            alert(res.message);
        }
    }

    const getActionCategories = async function () {
        const req = await get(`/proceedings/actions/categories`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            setActionCategories(res)
        } else {
            alert(res.message);
        }
    }

    const setTaskAsCompleted = async function (taskId) {
        const req = await put(`/tasks/byProceedingId/asCompleted/`, getUserToken(), { taskId: taskId, proceedingId: getProceedingId() });
        const res = await req.json();

        if (req.status === 200) {
            getTasks();
        } else {
            alert(res.message);
        }
    }

    const delay = ms => new Promise(res => setTimeout(res, ms));

    const createPreAgreement = async function () {
        setPreAgreementButton(<span><Spinner animation="grow" variant="light" size="sm" /><Spinner animation="grow" variant="light" size="sm" className='ms-1' /><Spinner animation="grow" variant="light" size="sm" className='ms-1' /></span>)

        const pa = {
            contactId: selectedCustomerContacts.id,
            lawerId: selectedLawer,
            debtorId: selectedDebtor,
            proceedingId: getProceedingId(),
            date: preAgreementDate
        };

        const req = await post(`/proceedings/debts/preagreement/`, getUserToken(), pa);
        const res = await req.json();

        if (req.status === 201) {
            await delay(500);
            triggerBase64Download(`data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64,${res.data}`, 'Pre Acuerdo');
            await delay(500);
            handleClosePreAgreementModal();
        } else {
            alert(res.message);
            setPreAgreementButton(<span><FontAwesomeIcon icon={faDownload} className='me-1'></FontAwesomeIcon>Descargar</span>)
        }
    }

    const createAction = async function () {
        if (checkNewAction()) {
            let file = null;
            let fileName = null;
            let fileExtension = null;

            if (newActionFile != null) {
                file = await convert2Base64(newActionFile);
                const aux = newActionFile.name.split('.')
                fileExtension = `.${aux[aux.length - 1]}`
                fileName = newActionFile.name;
            }

            const ac = {
                proceedingId: getProceedingId(),
                userId: selectedLawer,
                categoryId: selectedActionCategory,
                file: file,
                fileName: fileName,
                fileExtension: fileExtension,
                description: document.getElementById('newActionDescription').value,
                foja: document.getElementById('newActionFoja').value,
                date: newActionDate
            };

            const req = await post(`/proceedings/actions/`, getUserToken(), ac);
            const res = await req.json();

            if (req.status === 201) {
                getDebtById(getProceedingId());
                handleCloseNewActionModal();
            } else {
                alert(res.message);
            }
        }
    }

    const checkNewAction = () => {
        let result = true;

        if (selectedLawer == '0') {
            result = false;
            showError('No se ha asignado un encargado')
        } else if (selectedActionCategory == '0') {
            result = false;
            showError('No se ha seleccionado una categoría')
        } else if (document.getElementById("newActionDescription").value.length <= 1) {
            result = false;
            showError('No se ha escrito una descripción')
        }

        return result;
    }

    const createTask = async function () {
        const task = {
            proceedingId: getProceedingId(),
            userId: newTaskUser,
            dueDate: newTaskDate,
            alertId: newTaskReminder,
            title: document.getElementById("newTaskTitle").value,
            description: document.getElementById("newTaskDescription").value,
            isEvent: true
        };

        const req = await post(`/tasks/`, getUserToken(), task);
        const res = await req.json();

        if (req.status === 201) {
            getTasks();
            handleCloseNewTaskModal();
        } else {
            alert(res.message);
        }
    }

    const showError = (title) => {
        setError(
            <Alert className='mt-3 mb-0' key='danger' variant='danger'>
                {title}
            </Alert>
        )
    }

    const downloadFile = async (fileId, name) => {
        const req = await get(`/files/byId/${fileId}`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            const linkSource = res.file;
            const downloadLink = document.createElement("a");
            downloadLink.href = linkSource;
            downloadLink.download = name;
            downloadLink.click();
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const deleteAction = async function () {
        const data = {
            proceedingId: getProceedingId(),
            id: selectedActionId
        }
        const req = await del(`/proceedings/actions/`, getUserToken(), data);
        const res = await req.json();

        if (req.status === 201) {
            getDebtById(getProceedingId())
            handleCloseDeleteActionModal()
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const handleDues = (textId) => {
        let qty = document.getElementById('textDueQty').value;
        if (qty.length > 0) {
            qty = parseInt(qty);

            let d = [];
            let auxAmount;
            let auxInterests;
            let auxFee;
            let auxTotal;

            let leftAmount = parseFloat(debt.capital);
            let leftInterests = parseFloat(debt.interests);
            let leftFee = parseFloat(debt.totalFee);
            let leftTotal = parseFloat(debt.totalDebt)

            for (let i = 0; i < qty; i++) {
                auxAmount = parseFloat(document.getElementById(`textDueAmount${i}`).value).toFixed(2);
                auxInterests = parseFloat(document.getElementById(`textDueInterests${i}`).value).toFixed(2);
                auxFee = parseFloat(document.getElementById(`textDueFee${i}`).value).toFixed(2);
                auxTotal = 0;

                if (!isNaN(auxAmount)) {
                    if (auxAmount.length > 0) {
                        auxTotal += parseFloat(auxAmount);
                        leftAmount -= parseFloat(auxAmount);
                    }
                }

                if (!isNaN(auxFee)) {
                    if (auxFee.length > 0) {
                        auxTotal += parseFloat(auxFee);
                        leftFee -= parseFloat(auxFee);
                    }
                }

                if (!isNaN(auxInterests)) {
                    if (auxInterests.length > 0) {
                        auxTotal += parseFloat(auxInterests);
                        leftInterests -= parseFloat(auxInterests);
                    }
                }


                document.getElementById(`textDueTotal${i}`).value = auxTotal.toFixed(2);

                leftTotal -= auxTotal;

                d.push({
                    dueNumber: i + 1,
                    amount: auxAmount,
                    fee: auxFee,
                    interests: auxInterests,
                    total: auxTotal
                })

                if (!isNaN(auxAmount) && !isNaN(auxFee) && !isNaN(auxInterests)) {

                }

            }
            
            document.getElementById('textDueAmountLeft').value = leftAmount.toFixed(2) == "-0.00" ? "0.00" : leftAmount.toFixed(2);
            document.getElementById('textDueInterestsLeft').value = leftInterests.toFixed(2) == "-0.00" ? "0.00" : leftInterests.toFixed(2);
            document.getElementById('textDueFeeLeft').value = leftFee.toFixed(2) == "-0.00" ? "0.00" : leftFee.toFixed(2);
            document.getElementById('textDueTotalLeft').value = leftTotal.toFixed(2) == "-0.00" ? "0.00" : leftTotal.toFixed(2);

            if(parseFloat(document.getElementById('textDueAmountLeft').value) < 0){
                const e = document.getElementById('textDueAmountLeft');
                e.classList.add('text-error')
            }else{
                const e = document.getElementById('textDueAmountLeft');
                e.classList.remove('text-error')
            }

            if(parseFloat(document.getElementById('textDueInterestsLeft').value) < 0){
                const e = document.getElementById('textDueInterestsLeft');
                e.classList.add('text-error')
            }else{
                const e = document.getElementById('textDueInterestsLeft');
                e.classList.remove('text-error')
            }

            if(parseFloat(document.getElementById('textDueFeeLeft').value) < 0){
                const e = document.getElementById('textDueFeeLeft');
                e.classList.add('text-error')
            }else{
                const e = document.getElementById('textDueFeeLeft');
                e.classList.remove('text-error')
            }
        }
    };


    const calculateDues = () => {
        let qty = document.getElementById('textDueQty').value;
        if (qty.length > 0) {
            qty = parseInt(qty);
            const amount = parseFloat(debt.capital) / qty;
            const interests = parseFloat(debt.interests) / qty;
            const fee = parseFloat(debt.totalFee) / qty;
            const total = parseFloat(debt.totalDebt) / qty;

            let leftAmount = parseFloat(debt.capital);
            let leftInterests = parseFloat(debt.interests);
            let leftFee = parseFloat(debt.totalFee);
            let leftTotal = parseFloat(debt.totalDebt)

            for (let i = 0; i < qty; i++) {
                if (i < qty - 1) {
                    document.getElementById(`textDueAmount${i}`).value = amount.toFixed(2);
                    document.getElementById(`textDueInterests${i}`).value = interests.toFixed(2);
                    document.getElementById(`textDueFee${i}`).value = fee.toFixed(2);
                    document.getElementById(`textDueTotal${i}`).value = total.toFixed(2);

                    leftAmount -= amount.toFixed(2);
                    leftInterests -= interests.toFixed(2);
                    leftFee -= fee.toFixed(2);
                    leftTotal -= total.toFixed(2);
                } else {
                    document.getElementById(`textDueAmount${i}`).value = leftAmount.toFixed(2);
                    document.getElementById(`textDueInterests${i}`).value = leftInterests.toFixed(2);
                    document.getElementById(`textDueFee${i}`).value = leftFee.toFixed(2);
                    document.getElementById(`textDueTotal${i}`).value = leftTotal.toFixed(2);
                }
            }
        }

        handleDues();
    }

    const handleQtyDues = (event) => {
        const qty = parseInt(event.target.value);
        let d = [];
        for (let i = 0; i < qty; i++) {
            d.push({
                dueNumber: i + 1
            })
        }
        setDues(d);
        handleDues(null);
        resetDueFields();
    };

    const resetDueFields = () => {
        let i = 0;

        while (document.getElementById(`textDueAmount${i}`) != null) {
            document.getElementById(`textDueAmount${i}`).value = "";
            document.getElementById(`textDueInterests${i}`).value = "";
            document.getElementById(`textDueFee${i}`).value = "";
            document.getElementById(`textDueTotal${i}`).value = "";
            i++;
        }

        document.getElementById('textDueAmountLeft').value = "";
        document.getElementById('textDueInterestsLeft').value = "";
        document.getElementById('textDueFeeLeft').value = "";
        document.getElementById('textDueTotalLeft').value = "";
    }

    const deleteProceeding = async function () {
        const data = {
            proceedingId: getProceedingId(),
        }
        const req = await del(`/proceedings/`, getUserToken(), data);
        const res = await req.json();

        if (req.status === 201) {
            window.location.href = "/moras"
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const refreshInterests = async function () {
        const data = {
            proceedingId: getProceedingId(),
        }
        const req = await put(`/proceedings/debts/rate`, getUserToken(), data);
        const res = await req.json();

        if (req.status === 201) {
            window.location.reload();
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const changeModule = async function () {
        const req = await put(`/proceedings/module`, getUserToken(), { module: selectedModule, proceedingId: getProceedingId() });
        const res = await req.json();

        if (req.status === 200) {
            if (selectedModule == "Archivo") {
                window.location.href = '/archivo'
            } else if (selectedModule == "Judicial") {
                window.location.href = '/expedientes'
            } else if (selectedModule == "Deuda") {
                window.location.href = '/moras'
            }

        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    return (
        <div className='p-0' style={{ fontFamily: 'Roboto' }}>
            <style>{'body {background-color: #EBEBEB !important;}'}</style>
            <NavBar />

            <div style={{ height: 300, background: '#FAFAFA' }}>
                <Container>
                    <Row style={{ paddingTop: 10 }}>
                        <table className='w-75' style={{ marginLeft: 'auto', marginRight: 'auto' }}>
                            <tr>
                                <td>
                                    <p className='m-0' style={{ color: '#A0A0A0', fontSize: 25, fontWeight: 300 }}>EXP-GDD #{debt.id}</p>
                                    <p className='m-0' style={{ color: '#A0A0A0', fontSize: 45, fontWeight: 300 }}>{debt.name}</p>
                                </td>
                                <td style={{ fontSize: 140, width: 150 }}>
                                    <FontAwesomeIcon icon={PAGE_ICON} style={{ fontSize: 140, color: "A0A0A0" }} />
                                </td>
                            </tr>
                        </table>
                    </Row>
                </Container>
            </div>

            <Container>
                <Row style={{ marginTop: -70 }}>
                    <Card className='cards content mb-5'>
                        <table className='content-options content'>
                            <tr>
                                <td className='content-options rows left'>
                                    <Link to={'/moras'} className='link-primary'>
                                        <FontAwesomeIcon icon={faArrowLeft} style={{ marginRight: 8 }} />Volver a Gestión de deudas
                                    </Link>
                                    <span className='ms-2'>|</span><span className='ms-2'>{debt.User ? <img className='avatar-30 me-2' src={`${params().dataUri}${debt.User.avatar}`}></img> : ""}{debt.User ? `${debt.User.name} ${debt.User.surname}` : ""}</span>
                                </td>
                                <td className='content-options rows right'>
                                    <Dropdown>
                                        <Dropdown.Toggle variant="primary" id="dropdown-basic">
                                            <span style={{ marginRight: 10 }}><FontAwesomeIcon icon={faEllipsisV} /></span>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Dropdown.Item onClick={handleShowPreAgreementModal}><FontAwesomeIcon icon={faFileWord} style={{ marginRight: 11, marginLeft: 3 }} />Generar pre-acuerdo</Dropdown.Item>
                                            <Dropdown.Item onClick={handleShowCalcDuesModal}><FontAwesomeIcon icon={faWallet} style={{ marginRight: 8, marginLeft: 3 }} />Ver simulador de cuotas</Dropdown.Item>
                                            <Dropdown.Item onClick={refreshInterests}><FontAwesomeIcon icon={faRefresh} style={{ marginRight: 8, marginLeft: 2 }} />Actualizar intereses</Dropdown.Item>
                                            <Dropdown.Item onClick={handleShowChangeModuleModal}><FontAwesomeIcon icon={faShare} style={{ marginRight: 8, marginLeft: 2 }} />Mover de módulo</Dropdown.Item>
                                            <Dropdown.Item onClick={handleShowDeleteProceedingModal}><FontAwesomeIcon icon={faTrash} style={{ marginRight: 10, marginLeft: 2 }} />Eliminar</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </td>
                            </tr>
                        </table>

                        <Container>
                            <Row>
                                {customer ?
                                    <Container>
                                        <Accordion className='p-0 mt-3' >
                                            <Accordion.Item eventKey="0">
                                                <Accordion.Header>
                                                    <p className='m-0'>
                                                        Datos del cliente: <span>{customer.Person != null ? `${customer.Person.surname.toUpperCase()}, ${customer.Person.name}` : <span>{customer.Business.legalName} {customer.Business.code}</span>}</span>
                                                    </p>
                                                </Accordion.Header>
                                                <Accordion.Body>
                                                    <Row>
                                                        <Col xs={6}>
                                                            <p className='column-title'>Datos del cliente</p>
                                                        </Col>
                                                        <Col xs={6}>
                                                            {customer.Business ?
                                                                <p className='column-title'>Contactos</p>
                                                                :
                                                                <div></div>
                                                            }
                                                        </Col>
                                                    </Row>
                                                    <Row className='mb-3' style={{ marginTop: -16 }}>

                                                        <Col xs={6}>
                                                            {customer.businessId == null ?
                                                                //Its a person
                                                                <div className='column-content'>
                                                                    <table>
                                                                        <tr>
                                                                            <td style={{ fontWeight: 'bold' }}>Nombre</td>
                                                                            <td className='ps-5'>{customer.Person != null ? customer.Person.surname.toUpperCase() : ""}, {customer.Person.name}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td style={{ fontWeight: 'bold' }}>DNI</td>
                                                                            <td className='ps-5'>{customer.Person.dni}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td style={{ fontWeight: 'bold' }}>Teléfono</td>
                                                                            <td className='ps-5'>{customer.Person.phone}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td style={{ fontWeight: 'bold' }}>Celular</td>
                                                                            <td className='ps-5'>{customer.Person.mobile}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td style={{ fontWeight: 'bold' }}>Correo</td>
                                                                            <td className='ps-5'>{customer.Person.email}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td style={{ fontWeight: 'bold' }}>Trabajo</td>
                                                                            <td className='ps-5'>{customer.Person.isWorking ? `Si - ${customer.Person.placeWork}` : 'No'}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td style={{ fontWeight: 'bold' }}>Domicilio</td>
                                                                            <td className='ps-5'>{customer.Person.address}<br></br>{customer.Person.city != null ? `${customer.Person.city}` : ''}</td>
                                                                        </tr>
                                                                    </table>
                                                                </div>
                                                                :
                                                                //Its a business
                                                                <div className='column-content'>
                                                                    <table>
                                                                        <tr>
                                                                            <td style={{ fontWeight: 'bold' }}>Nombre</td>
                                                                            <td className='ps-5'>{customer.Business.legalName}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td style={{ fontWeight: 'bold' }}>CUIT</td>
                                                                            <td className='ps-5'>{customer.Business.cuit}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td style={{ fontWeight: 'bold' }}>Orden</td>
                                                                            <td className='ps-5'>{customer.Business.educationalNetwork != null ? customer.Business.educationalNetwork.name : "---"}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td style={{ fontWeight: 'bold' }}>Domicilio</td>
                                                                            <td className='ps-5'>{customer.Business.address}<br></br>{customer.Business.city != null ? `${customer.Business.city}` : ''}</td>
                                                                        </tr>
                                                                    </table>
                                                                </div>
                                                            }
                                                        </Col>
                                                        <Col xs={6}>
                                                            {customer.Business != null ?
                                                                <div className='column-content'>
                                                                    {customerContacts.length > 0 ?
                                                                        <Table className=''>

                                                                            <tbody>
                                                                                {customerContacts.map((r, i) => (
                                                                                    <tr key={i} >
                                                                                        <td className='content-table rows align-middle ps-4'>{r.name}</td>
                                                                                        <td className='content-table rows align-middle'>{r.data}</td>
                                                                                    </tr>
                                                                                ))}

                                                                            </tbody>
                                                                        </Table>

                                                                        :
                                                                        <Container className='mt-4 mb-4'>
                                                                            <Row>
                                                                                <Col style={{ textAlign: 'center', fontSize: 40 }}>
                                                                                    <FontAwesomeIcon icon={faAddressBook} />
                                                                                </Col>
                                                                            </Row>
                                                                            <Row>
                                                                                <Col style={{ textAlign: 'center' }}>
                                                                                    No hay contactos
                                                                                </Col>
                                                                            </Row>
                                                                        </Container>
                                                                    }
                                                                </div>
                                                                :
                                                                <div></div>
                                                            }
                                                        </Col>

                                                    </Row>

                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </Accordion>
                                    </Container>
                                    :
                                    <div></div>

                                }

                            </Row>
                            <Row>
                                {defendants.length > 0 ?
                                    <Container>
                                        <Accordion className='p-0 mt-3' >
                                            <Accordion.Item eventKey="0">
                                                <Accordion.Header>
                                                    <p className='m-0'>
                                                        Datos del contrario/s: {defendants.map((d, i) => (<span>{d.Person != null ? `${d.Person.surname.toUpperCase()}, ${d.Person.name}${defendants.length > (i + 1) ? "; " : ""}` : `${d.Business.legalName}${defendants.length > (i + 1) ? "; " : ""}`}</span>))}
                                                    </p>
                                                </Accordion.Header>
                                                <Accordion.Body>
                                                    <Row>
                                                        {defendants.map((d, i) => (
                                                            <Col xs={4}>
                                                                <p className='column-title'>Contrario {i + 1}</p>
                                                            </Col>
                                                        ))}
                                                    </Row>
                                                    <Row className='mb-3' style={{ marginTop: -16 }}>
                                                        {defendants.map((d, i) => (
                                                            <Col xs={4}>
                                                                {d.businessId == null ?
                                                                    //Its a person
                                                                    <div className='column-content'>
                                                                        <table>
                                                                            <tr>
                                                                                <td style={{ fontWeight: 'bold' }}>Nombre</td>
                                                                                <td className='ps-5'>{d.Person != null ? d.Person.surname.toUpperCase() : ""}, {d.Person.name}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td style={{ fontWeight: 'bold' }}>DNI</td>
                                                                                <td className='ps-5'>{d.Person.dni}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td style={{ fontWeight: 'bold' }}>Teléfono</td>
                                                                                <td className='ps-5'>{d.Person.phone}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td style={{ fontWeight: 'bold' }}>Celular</td>
                                                                                <td className='ps-5'>{d.Person.mobile}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td style={{ fontWeight: 'bold' }}>Correo</td>
                                                                                <td className='ps-5'>{d.Person.email}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td style={{ fontWeight: 'bold' }}>Trabajo</td>
                                                                                <td className='ps-5'>{d.Person.isWorking ? `Si - ${d.Person.placeWork}` : 'No'}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td style={{ fontWeight: 'bold' }}>Domicilio</td>
                                                                                <td className='ps-5'>{d.Person.address}<br></br>{d.Person.city != null ? `${d.Person.city}` : ''}</td>
                                                                            </tr>
                                                                        </table>
                                                                    </div>
                                                                    :
                                                                    //Its a business
                                                                    <div className='column-content'>
                                                                        <table>
                                                                            <tr>
                                                                                <td style={{ fontWeight: 'bold' }}>Nombre</td>
                                                                                <td className='ps-5'>{d.Business.legalName}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td style={{ fontWeight: 'bold' }}>CUIT</td>
                                                                                <td className='ps-5'>{d.Business.cuit}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td style={{ fontWeight: 'bold' }}>Orden</td>
                                                                                <td className='ps-5'>{d.Business.educationalNetwork != null ? d.Business.educationalNetwork.name : "---"}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td style={{ fontWeight: 'bold' }}>Domicilio</td>
                                                                                <td className='ps-5'>{d.Business.address}<br></br>{d.Business.city != null ? `${d.Business.city}` : ''}</td>
                                                                            </tr>
                                                                        </table>
                                                                    </div>
                                                                }
                                                            </Col>
                                                        ))}
                                                    </Row>

                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </Accordion>
                                    </Container>
                                    :
                                    <div></div>

                                }

                            </Row>



















                            <Row className='mt-4 mb-0'>
                                <Col>
                                    <p className='column-title'>Datos de la deuda</p>
                                </Col>
                                <Col>
                                    <p className='column-title'>Alumno/s</p>
                                </Col>
                                <Col>

                                </Col>
                            </Row>
                            <Row className='mb-4' style={{ marginTop: -16 }}>
                                <Col>
                                    <div className='column-content-nh mb-2'>
                                        <table>
                                            <tr>
                                                <td style={{ fontWeight: 'bold' }}>Capital</td>
                                                <td className='ps-5'>${pn(debt.capital)}</td>
                                            </tr>
                                            <tr>
                                                <td style={{ fontWeight: 'bold' }}>Intereses</td>
                                                <td className='ps-5'>${pn(debt.interests)}</td>
                                            </tr>
                                            <tr>
                                                <td style={{ fontWeight: 'bold' }}>Honorarios</td>
                                                <td className='ps-5'>${pn(debt.totalFee)} ({debt.fee}%)</td>
                                            </tr>
                                            <tr>
                                                <td style={{ fontWeight: 'bold' }}>Total deuda</td>
                                                <td className='ps-5'><Badge bg="secondary" style={{ fontSize: 14 }}>${pn(debt.totalDebt)}</Badge></td>
                                            </tr>

                                        </table>
                                    </div>


                                </Col>
                                <Col>
                                    <div className='column-content-nh mb-2'>
                                        <Form>
                                            <Form.Group>
                                                {students.map((s, i) => (
                                                    <Form.Control className={i > 0 ? 'mt-2' : ''} disabled type="text" id="" placeholder="" value={`${s.dni ? `DNI ${s.dni} - ` : ""}${s.surname.toUpperCase()}, ${s.name}`} />
                                                ))}
                                            </Form.Group>
                                        </Form>
                                    </div>
                                </Col>
                                <Col>

                                </Col>
                            </Row>

                            {isArchived ?
                                <div></div>
                                :
                                <div>
                                    <Row className='mt-4 mb-0'>
                                        <Col>
                                            <p className='column-title'>Tareas</p>
                                        </Col>
                                    </Row>
                                    <Row className='mb-4' style={{ marginTop: -16 }}>
                                        <Col>
                                            <div className='column-content'>
                                                <div className='mt-0 mb-3' style={{ textAlign: 'right' }}>
                                                    <Button variant="success" onClick={handleShowNewTaskModal}><FontAwesomeIcon icon={faPlusCircle} style={{ marginRight: 8 }} />Nueva tarea</Button>
                                                </div>

                                                {tasks.length > 0 ?
                                                    tasks.map((t, i) => (
                                                        <div className='file element mb-2' >
                                                            <table>
                                                                <tr>
                                                                    <td className='w-100'>
                                                                        <p className='m-0'>{t.title}</p>
                                                                        <p className='m-0' style={{ fontSize: 12 }}>{t.description}</p>
                                                                        <p className='mt-1 mb-0'>{timeDueWithBadge(t.dueDate)}</p>
                                                                        <p className='mt-2 mb-0' style={{ fontSize: 12 }}><img className='me-1 avatar-20' src={`${params().dataUri}${t.User.avatar}`} alt=""></img> <span style={{ marginTop: 'auto', marginBottom: 'auto', height: 20, verticalAlign: 'middle' }}>{t.User.name} {t.User.surname}</span></p>
                                                                    </td>
                                                                    <td className='w-100'>
                                                                        <Dropdown>
                                                                            <Dropdown.Toggle variant="light" id="dropdown-basic">
                                                                                <span style={{ marginRight: 10 }}><FontAwesomeIcon icon={faEllipsisV} /></span>
                                                                            </Dropdown.Toggle>
                                                                            <Dropdown.Menu>
                                                                                <Dropdown.Item onClick={() => setTaskAsCompleted(t.id)}><FontAwesomeIcon icon={faCheck} style={{ marginRight: 10, marginLeft: 2 }} />Marcar como completado</Dropdown.Item>
                                                                            </Dropdown.Menu>
                                                                        </Dropdown>
                                                                    </td>
                                                                </tr>
                                                            </table>
                                                        </div>
                                                    ))
                                                    :
                                                    <Container className='mt-4 mb-4'>
                                                        <Row>
                                                            <Col style={{ textAlign: 'center', fontSize: 40 }}>
                                                                <FontAwesomeIcon icon={faTasks} />
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col style={{ textAlign: 'center' }}>
                                                                No hay tareas pendientes
                                                            </Col>
                                                        </Row>
                                                    </Container>}



                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            }



                            <Row className='mt-4 mb-0'>
                                <Col>
                                    <p className='column-title'>Gestión</p>
                                </Col>
                            </Row>
                            <Row className='mb-4' style={{ marginTop: -16 }}>
                                <Col>
                                    <div className='column-content'>
                                        {isArchived ?
                                            <div></div>
                                            :
                                            <div className='mt-0 mb-3' style={{ textAlign: 'right' }}>
                                                <Button variant="success" onClick={handleShowNewActionModal}><FontAwesomeIcon icon={faPlusCircle} style={{ marginRight: 8 }} />Nueva actividad</Button>
                                            </div>
                                        }

                                        <table striped hover className='mb-3 w-100'>
                                            <thead>
                                                <tr>
                                                    {proceedingActions.length > 0 ? proceedingActionsColumns.map((c, i) => (
                                                        <th key={i} className='content-table head'>{c}</th>
                                                    )) : <Container className='mt-4 mb-4'>
                                                        <Row>
                                                            <Col style={{ textAlign: 'center', fontSize: 40 }}>
                                                                <FontAwesomeIcon icon={faCommentAlt} />
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col style={{ textAlign: 'center' }}>
                                                                No se han realizado actividades
                                                            </Col>
                                                        </Row>
                                                    </Container>}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {proceedingActions.map((r, i) => (
                                                    <tr key={i} >
                                                        <td className='content-table rows middle align-middle' style={{ width: 120 }}>{<Moment format="DD/MM/YYYY">{r.date}</Moment>}</td>
                                                        <td className='content-table rows middle align-middle' style={{ width: 160 }}>{r.category}</td>
                                                        <td className='content-table rows align-middle'>{r.description}</td>
                                                        <td className='content-table rows middle align-middle' style={{ width: 120 }}>{r.foja}</td>
                                                        <td className='content-table rows align-middle' style={{ width: 30 }}>{r.fileIcon}</td>
                                                        <td className='content-table rows middle align-middle' style={{ width: 200 }}><img className='avatar-30 me-2' src={`${params().dataUri}${r.User.avatar}`} />{r.createdBy}</td>
                                                        <td className='content-table rows middle align-middle' style={{ width: 50 }}>{r.action}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>

                                    </div>
                                </Col>
                            </Row>

                            <Row className='mt-4 mb-0'>
                                <Col>
                                    <p className='column-title'>Liquidación</p>
                                </Col>
                            </Row>
                            <Row className='mb-4' style={{ marginTop: -16 }}>
                                <Col>
                                    <div className='column-content'>
                                        <table striped hover className='mb-3 w-100'>
                                            <thead>
                                                <tr>
                                                    {debtDetailsColumns.map((c, i) => (
                                                        <th key={i} className='content-table head'>{c}</th>
                                                    ))}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {debtDetails.map((r, i) => (
                                                    <tr key={i} >
                                                        <td className='content-table rows middle align-middle ps-4'>{r.studentName}</td>
                                                        <td className='content-table rows middle align-middle'>{<Moment format="DD/MM/YYYY">{r.date}</Moment>}</td>
                                                        <td className='content-table rows middle align-middle'>${pn(r.amount)}</td>
                                                        <td className='content-table rows middle align-middle'>{r.days}</td>
                                                        <td className='content-table rows middle align-middle'>${pn(r.interests)}</td>
                                                        <td className='content-table rows middle align-middle'>${pn(r.total)}</td>
                                                        <td className='content-table rows align-middle'>{r.description}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </Col>
                            </Row>


                        </Container>
                    </Card>
                </Row>


            </Container>

            <Modal show={showPreAgreementModal} onHide={handleClosePreAgreementModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Generar pre-acuerdo</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group>
                        <Form.Text>Nombre Apoderado legal</Form.Text>
                        <Form.Select className='cover content rows element' id="" onChange={(event) => handlePreAgreementApoderadoLegalNombre(event)}>
                            <option value="0">Seleccionar...</option>
                            {customerContacts.map((p, i) => (
                                <option key={i} id={i} value={p.id}>{p.name}</option>
                            ))}
                        </Form.Select>
                    </Form.Group>

                    <Form.Group className='mt-2'>
                        <Form.Text>DNI Apoderado legal</Form.Text>
                        <Form.Control type="text" readOnly id="preagreementApoderadoLegalDNI" placeholder="" />
                    </Form.Group>

                    <Form.Group className='mt-2'>
                        <Form.Text>Deudor</Form.Text>
                        <Form.Select className='cover content rows element' id="" onChange={(event) => handlePreAgreementDebtor(event)}>
                            <option value="0">Seleccionar...</option>
                            {defendants.map((p, i) => (
                                <option key={i} id={i} value={p.Person.id}>{p.Person.name} {p.Person.surname}</option>
                            ))}
                        </Form.Select>
                    </Form.Group>

                    <Form.Group className='mt-2'>
                        <Form.Text>Abogado</Form.Text>
                        <Form.Select className='cover content rows element' id="" onChange={(event) => handlePreAgreementLawer(event)}>
                            <option value="0">Seleccionar...</option>
                            {lawers.map((p, i) => (
                                <option key={i} id={i} value={p.id}>{p.name} {p.surname}</option>
                            ))}
                        </Form.Select>
                    </Form.Group>

                    <Form.Group className='mt-2'>
                        <Form.Text>Fecha liquidación</Form.Text>
                        <DatePicker className='form-control cover content rows element' locale="es" id='textDebtDate' dateFormat="dd/MM/yyyy" selected={preAgreementDate} onChange={(d) => handlePreAgreementDate(d)} />
                    </Form.Group>



                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClosePreAgreementModal}>
                        Cerrar
                    </Button>

                    <Button variant="success" onClick={createPreAgreement}>{preAgreementButton}</Button>


                </Modal.Footer>
            </Modal>

            <Modal show={showNewActionModal} onHide={handleCloseNewActionModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Nueva actividad</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group>
                        <Form.Text>Fecha</Form.Text>
                        <DatePicker className='form-control cover content rows element' locale="es" id='' dateFormat="dd/MM/yyyy" selected={newActionDate} onChange={(d) => handleNewActionDate(d)} />
                    </Form.Group>

                    <Form.Group className='mt-2'>
                        <Form.Text>Responsable</Form.Text>
                        <Form.Select className='cover content rows element' id="" onChange={(event) => handleNewActionUser(event)}>
                            <option value="0">Seleccionar...</option>
                            {lawers.map((p, i) => (
                                <option key={i} id={i} value={p.id}>{p.name} {p.surname}</option>
                            ))}
                        </Form.Select>
                    </Form.Group>
                    <Form.Group className='mt-2'>
                        <Form.Text>Categoría</Form.Text>
                        <Form.Select className='cover content rows element' id="" onChange={(event) => handleNewActionCategory(event)}>
                            <option value="0">Seleccionar...</option>
                            {actionCategories.map((p, i) => (
                                <option key={i} id={i} value={p.id}>{p.name}</option>
                            ))}
                        </Form.Select>
                    </Form.Group>
                    <Form.Group className='mt-2'>
                        <Form.Text>Gestión</Form.Text>
                        <Form.Control as="textarea" rows={4} id="newActionDescription" placeholder="" />
                    </Form.Group>

                    <Form.Group className='mt-2'>
                        <Form.Text>Foja</Form.Text>
                        <Form.Control type="text" id="newActionFoja" placeholder="" />
                    </Form.Group>

                    <Form.Group className="mt-2">
                        <Form.Text>Archivo</Form.Text>
                        <Form.Control accept='.pdf, .docx, .xlsx, .png, .jpg, .jpeg, .zip' onChange={(event) => handleNewActionFile(event)} type="file" />
                    </Form.Group>

                    {error}

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseNewActionModal}>
                        Cerrar
                    </Button>

                    <Button variant="success" onClick={createAction}>Crear actividad</Button>


                </Modal.Footer>
            </Modal>


            <Modal show={showNewTaskModal} onHide={handleCloseNewTaskModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Nueva tarea</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <Form.Group>
                        <Form.Text>Encargado</Form.Text>
                        <Row>
                            <Col>
                                <Form.Select className='cover content rows element' id="" onChange={(event) => handleNewTaskUser(event)}>
                                    <option value="0">Seleccionar...</option>
                                    {lawers.map((p, i) => (
                                        <option key={i} id={i} value={p.id}>{p.name} {p.surname}</option>
                                    ))}
                                </Form.Select>
                            </Col>
                            <Col xs={3} style={{ textAlign: 'right' }}>
                                <ToggleButton
                                    className=""
                                    id="newTaskNotification"
                                    type="checkbox"
                                    variant="outline-success"
                                    checked={newTaskNotificationChecked}
                                    value="1"
                                    onChange={(e) => handleNewTaskNotification(e.currentTarget.checked)}
                                >
                                    Notificar
                                </ToggleButton>
                            </Col>
                        </Row>



                    </Form.Group>

                    <Form.Group className='mt-2'>
                        <Form.Text>Título</Form.Text>
                        <Form.Control type="text" id="newTaskTitle" placeholder="" />
                    </Form.Group>

                    <Form.Group className='mt-2'>
                        <Form.Text>Descripción</Form.Text>
                        <Form.Control as="textarea" rows={4} id="newTaskDescription" placeholder="" />
                    </Form.Group>

                    <Form.Group className='mt-2'>
                        <Form.Text>Fecha</Form.Text>
                        <DatePicker className='form-control cover content rows element' locale="es" minDate={new Date()} id='' dateFormat="dd/MM/yyyy" selected={newTaskDate} onChange={(d) => handleNewTaskDate(d)} />
                    </Form.Group>

                    <Form.Group className='mt-2'>
                        <Form.Text>Recordar</Form.Text>
                        <Form.Select className='cover content rows element' id="" onChange={(event) => handleNewTaskReminder(event)}>
                            <option value="0">No recordar</option>
                            {reminderOptions.map((p, i) => (
                                <option key={i} id={i} value={p.id}>{p.name}</option>
                            ))}
                        </Form.Select>
                    </Form.Group>

                    {error}

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseNewTaskModal}>
                        Cerrar
                    </Button>

                    <Button variant="success" onClick={createTask}>Crear tarea</Button>


                </Modal.Footer>
            </Modal>

            <Modal show={showDeleteActionModal} onHide={handleCloseDeleteActionModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Eliminar</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    ¿Desea eliminar la gestión "{selectedActionDescription}"?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseDeleteActionModal}>
                        Cerrar
                    </Button>
                    <Button variant="danger" onClick={deleteAction}>
                        Eliminar
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showDeleteProceedingModal} onHide={handleCloseDeleteProceedingModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Eliminar</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    ¿Desea eliminar el expediente?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseDeleteProceedingModal}>
                        Cerrar
                    </Button>
                    <Button variant="danger" onClick={deleteProceeding}>
                        Eliminar
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showCalcDuesModal} size={"lg"} onHide={handleCloseCalcDuesModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Simulador de cuotas</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <Form>
                        <Row>
                            <Col>
                                <p className='m-0'>CAPI <Badge bg="secondary" style={{ fontSize: 14 }}>${pn(debt.capital)}</Badge></p>
                            </Col>
                            <Col>
                                <p className='m-0'>INTE <Badge bg="secondary" style={{ fontSize: 14 }}>${pn(debt.interests)}</Badge></p>
                            </Col>
                            <Col>
                                <p className='m-0'>HONO <Badge bg="secondary" style={{ fontSize: 14 }}>${pn(debt.totalFee)}</Badge></p>
                            </Col>
                            <Col>
                                <p className='m-0'>TOTAL <Badge bg="primary" style={{ fontSize: 14 }}>${pn(debt.totalDebt)}</Badge></p>
                            </Col>
                        </Row>
                        <table className='w-100 mt-3'>
                            <tr>
                                <td style={{ width: 160}}>Cantidad de cuotas:</td>
                                <td style={{ width: 100 }}>
                                    <Form.Group>
                                        <Form.Control className='' type="text" id="textDueQty" placeholder="" onChange={(event) => handleQtyDues(event)} />
                                    </Form.Group>
                                </td>
                                <td className='ps-4'>
                                    <Button variant="primary" onClick={calculateDues}>Calcular cuotas</Button>
                                </td>

                            </tr>
                        </table>
                    </Form>

                    <table striped hover className='mb-3 mt-3 w-100'>
                        <thead>
                            <tr>
                                {dueColumns.map((c, i) => (
                                    <th key={i} className='content-table head'>{c}</th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {dues.map((r, i) => (
                                <tr key={i} >
                                    <td className='content-table rows middle align-middle' style={{ width: 140 }}>{r.dueNumber}</td>
                                    <td className='content-table rows middle align-middle'>
                                        <Form.Control type="text" id={`textDueAmount${i}`} placeholder="" onChange={(event) => handleDues(i)} />
                                    </td>
                                    <td className='content-table rows middle align-middle'>
                                        <Form.Control type="text" id={`textDueInterests${i}`} placeholder="" onChange={(event) => handleDues(i)} />
                                    </td>
                                    <td className='content-table rows middle align-middle'>
                                        <Form.Control type="text" id={`textDueFee${i}`} placeholder="" onChange={(event) => handleDues(i)} />
                                    </td>
                                    <td className='content-table rows middle align-middle'>
                                        <Form.Control type="text" id={`textDueTotal${i}`} readOnly placeholder="" />
                                    </td>
                                </tr>
                            ))}
                            <tr>
                                <td className='content-table rows middle align-middle' style={{ width: 140, background: '#ffdbdb' }}>Quita</td>
                                <td className='content-table rows middle align-middle' style={{background: '#ffdbdb'}}>
                                    <Form.Control type="text" id="textDueAmountLeft" placeholder="" readOnly />
                                </td>
                                <td className='content-table rows middle align-middle' style={{background: '#ffdbdb'}}>
                                    <Form.Control type="text" id="textDueInterestsLeft" placeholder="" readOnly />
                                </td>
                                <td className='content-table rows middle align-middle' style={{background: '#ffdbdb'}}>
                                    <Form.Control type="text" id="textDueFeeLeft" placeholder="" readOnly />
                                </td>
                                <td className='content-table rows middle align-middle' style={{background: '#ffdbdb'}}>
                                    <Form.Control type="text" id="textDueTotalLeft" readOnly placeholder="" />
                                </td>
                            </tr>
                        </tbody>
                    </table>



                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseCalcDuesModal}>
                        Cerrar
                    </Button>

                </Modal.Footer>
            </Modal>

            <Modal show={showChangeModuleModal} onHide={handleCloseChangeModuleModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Mover de módulo</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group className=''>
                        <Form.Text>Módulo</Form.Text>
                        <Form.Select className='cover content rows element' id="" onChange={(event) => handleSelectedModule(event)}>
                            <option value="0">Seleccionar...</option>
                            {!isArchived ? <option value='Judicial'>Judicial</option> : ""}
                            {!isArchived ? <option value='Archivo'>Archivo</option> : ""}
                            {isArchived ? <option value='Deuda'>Gestión de deudas</option> : ""}
                        </Form.Select>
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseChangeModuleModal}>
                        Cerrar
                    </Button>

                    <Button variant="danger" onClick={changeModule}>Mover</Button>


                </Modal.Footer>
            </Modal>
        </div>
    )
}