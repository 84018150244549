import React, { useEffect } from 'react'
import WebFont from 'webfontloader';
import Moment from 'react-moment';
import moment from 'moment';
// eslint-disable-next-line
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { Container, Row, Card, Button, Table, Dropdown, DropdownButton, Form, Col, Modal } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle, faGavel, faEye, faSearch, faFilter, faDownload, faEllipsisV } from '@fortawesome/free-solid-svg-icons'
import NavBar from "../navbar/Navbar";
import { get, post } from '../../../controllers/endpoints.controller';
import { getUserToken, logout } from '../../../controllers/user.controller';
import { timeSince } from '../../../tools/tools';
import { triggerBase64Download } from 'common-base64-downloader-react';

export default function CourtFiles() {

    const PAGE_TITLE = "Expedientes";
    const PAGE_ICON = faGavel;
    const [tableColumns, setTableColumns] = React.useState([]);
    const [tableRows, setTableRows] = React.useState([]);

    const [orderTypes, setOrderTypes] = React.useState(["Ordenar alfabéticamente (A -> Z)", "Ordenar alfabéticamente (Z -> A)", "Ordenar por encargado", "Ordenar por inactividad (menor a mayor)", "Ordenar por inactividad (mayor a menor)"]);
    const [selectedOrderType, setSelectedOrderType] = React.useState("Ordenar alfabéticamente (A -> Z)");
    const [selectedOnlyMine, setSelectedOnlyMine] = React.useState(false);

    const [showFilterModal, setShowFilterModal] = React.useState(false);
    const handleCloseFilterModal = () => { setShowFilterModal(false); };
    const handleShowFilterModal = () => {
        setShowFilterModal(true);
    };

    const handleOrderType = (event) => {
        setSelectedOrderType(event.target.value);
    };

    const handleOnlyMine = (event) => {
        setSelectedOnlyMine(event.target.checked)
    };

    useEffect(() => {
        WebFont.load({
            google: {
                families: ['Roboto:100,300,400']
            }
        });

        document.title = `Demo - ${PAGE_TITLE}`;

        let sort;

        if(localStorage.getItem('userSortExpedientes') != null){
            sort = localStorage.getItem('userSortExpedientes');
        }else{
            sort = "Ordenar alfabéticamente (A -> Z)";
            localStorage.setItem('userSortExpedientes', "Ordenar alfabéticamente (A -> Z)");
        }

        console.log(localStorage.getItem('userOnlyMineExpedientes'))

        let checkMine;

        if(localStorage.getItem('userOnlyMineExpedientes') != null){
            checkMine = localStorage.getItem('userOnlyMineExpedientes');
        }else{
            checkMine = false;
            localStorage.setItem('userOnlyMineExpedientes', false);
        }

        setSelectedOrderType(sort);
        setSelectedOnlyMine(checkMine === 'true');
        getCourtFiles();
        setupTable();
        
    }, []);

    const setupTable = function () {
        const cols = ['Carátula', 'Cliente', 'Encargado', 'Inicio', 'Inactividad', 'Acciones'];
        setTableColumns(cols);
    };

    const handleSearch = async function (event) {
        localStorage.setItem('userSortExpedientes', selectedOrderType);
        localStorage.setItem('userOnlyMineExpedientes', selectedOnlyMine);

        handleCloseFilterModal()
        const chain = {
            chain: event.target.value,
            orderType: selectedOrderType,
            onlyMine: selectedOnlyMine
        };

        const req = await post(`/proceedings/search/Judicial`, getUserToken(), chain);
        const res = await req.json();

        if (req.status === 200) {
            processCourtFiles(res)
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
            console.log(res)
        }
    }

    const getCourtFiles = async function () {
        let sort;

        if(localStorage.getItem('userSortExpedientes') != null){
            sort = localStorage.getItem('userSortExpedientes');
        }else{
            sort = "Ordenar alfabéticamente (A -> Z)";
            localStorage.setItem('userSortExpedientes', "Ordenar alfabéticamente (A -> Z)");
        }

        let checkMine;

        if(localStorage.getItem('userOnlyMineExpedientes') != null){
            checkMine = localStorage.getItem('userOnlyMineExpedientes');
        }else{
            checkMine = false;
            localStorage.setItem('userOnlyMineExpedientes', false);
        }

        const chain = {
            chain: "",
            orderType: sort,
            onlyMine: checkMine === 'true'
        };        

        const req = await post(`/proceedings/search/Judicial`, getUserToken(), chain);
        const res = await req.json();

        if (req.status === 200) {
            processCourtFiles(res)
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
            console.log(res)
        }
    }

    const processCourtFiles = (res) => {
        let result = [];

        for (let r of res) {
            let customer;
            if (r.ProceedingCustomer) {
                if (r.ProceedingCustomer.Business) { customer = r.ProceedingCustomer.Business.legalName }
                if (r.ProceedingCustomer.Person) { customer = `${r.ProceedingCustomer.Person.surname.toUpperCase()}, ${r.ProceedingCustomer.Person.name}` }
            }
            r.customer = customer;
            r.action = <Link to={`/expedientes/detalles/?id=${r.id}`}><Button className='buttonBlue' variant="primary"><FontAwesomeIcon icon={faEye} /></Button></Link>;
            //r.inactividad = parseInt((moment.duration(moment().diff(r.lastUpdate))).asDays()) + (parseInt((moment.duration(moment().diff(r.lastUpdate))).asDays()) == 1 ? ' día' : ' días');
            r.inactividad = timeSince(r.lastUpdate)
            result.push(r);
        }
        setTableRows(result);
    }

    const generateReport = async function () {
        localStorage.setItem('userSortExpedientes', selectedOrderType);
        localStorage.setItem('userOnlyMineExpedientes', selectedOnlyMine);

        handleCloseFilterModal()
        const chain = {
            chain: "",
            orderType: selectedOrderType,
            onlyMine: selectedOnlyMine
        };

        const req = await post(`/proceedings/courtFiles/report`, getUserToken(), chain);
        const res = await req.json();

        if (req.status === 200) {
            triggerBase64Download(res.file, 'Reporte')
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
            console.log(res)
        }
    }

    return (
        <div className='p-0' style={{ fontFamily: 'Roboto' }}>
            <style>{'body {background-color: #EBEBEB !important;}'}</style>
            <NavBar />

            <div style={{ height: 300, background: '#FAFAFA' }}>
                <Container>
                    <Row style={{ paddingTop: 10 }}>
                        <table className='w-75' style={{ marginLeft: 'auto', marginRight: 'auto' }}>
                            <tr>
                                <td style={{ fontSize: 45, fontWeight: 300 }}>
                                    <span style={{ color: '#A0A0A0' }}>{PAGE_TITLE}</span>
                                </td>
                                <td style={{ fontSize: 140, width: 150 }}>
                                    <FontAwesomeIcon icon={PAGE_ICON} style={{ fontSize: 140, color: "A0A0A0" }} />
                                </td>
                            </tr>
                        </table>
                    </Row>
                </Container>
            </div>

            <Container>
                <Row style={{ marginTop: -70 }}>
                    <Card className='cards content mb-5'>
                        <table className='content-options content'>
                            <tr>
                                <td className='content-options rows left'>
                                    <FontAwesomeIcon icon={faSearch}></FontAwesomeIcon><Form.Control className='ms-2' type="text" id="textFee" style={{ width: 250, display: 'inline' }} placeholder="" onChange={(event) => handleSearch(event)} />
                                    <Button className="ms-2" variant="primary" onClick={handleShowFilterModal}><FontAwesomeIcon icon={faFilter} style={{ marginRight: 8 }} />Filtros</Button>
                                </td>
                                <td className='content-options rows right'>
                                
                                    <Link to='/judicial/nueva/1'>
                                        <Button variant="success"><FontAwesomeIcon icon={faPlusCircle} style={{ marginRight: 8 }} />Nuevo expediente judicial</Button>
                                    </Link>
                                    <Dropdown className='ms-2' style={{display: 'inline'}}>
                                        <Dropdown.Toggle variant="primary" id="dropdown-basic">
                                            <span style={{ marginRight: 10 }}><FontAwesomeIcon icon={faEllipsisV} /></span>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Dropdown.Item onClick={generateReport}><FontAwesomeIcon icon={faDownload} style={{ marginRight: 8, marginLeft: 2 }} />Descargar reporte</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </td>
                            </tr>
                        </table>

                        {tableRows.length > 0 ?
                            <table striped hover className='mb-3'>
                                <thead>
                                    <tr>
                                        {tableColumns.map((c, i) => (
                                            <th key={i} className='content-table head'>{c}</th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>
                                    {tableRows.map((r, i) => (
                                        <tr key={i} >
                                            <td className='content-table rows middle align-middle'>{r.CourtFile ? r.CourtFile.name : ''}</td>
                                            <td className='content-table rows middle align-middle'>{r.customer}</td>
                                            <td className='content-table rows middle align-middle' style={{ width: 120 }}>{r.CourtFile ? `${r.CourtFile.User.name} ${(r.CourtFile.User.surname).substring(0, 1)}.`: ""}</td>
                                            <td className='content-table rows middle align-middle' style={{ width: 100 }}>{<Moment format="DD/MM/YYYY">{r.startDate}</Moment>}</td>
                                            <td className='content-table rows middle align-middle' style={{ width: 120 }}>{r.inactividad}</td>
                                            <td className='content-table rows middle align-middle' style={{ width: 100 }}>{r.action}</td>
                                        </tr>
                                    ))}

                                </tbody>
                            </table>
                            :
                            <Container className='mt-5 mb-5'>
                                <Row>
                                    <Col style={{ textAlign: 'center', fontSize: 60 }}>
                                        <FontAwesomeIcon icon={faGavel} />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col style={{ textAlign: 'center' }}>
                                        No hay expedientes activos
                                    </Col>
                                </Row>
                            </Container>

                        }

                    </Card>
                </Row>
            </Container>

            <Modal show={showFilterModal} onHide={handleCloseFilterModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Filtros</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group className=''>
                        <Form.Text>Orden</Form.Text>
                        <Form.Select className='cover content rows element' id="" value={selectedOrderType} onChange={(event) => handleOrderType(event)}>
                            {orderTypes.map((p, i) => (
                                <option key={i} id={i} value={p}>{p}</option>
                            ))}
                        </Form.Select>
                    </Form.Group>

                    <Form.Group className='mt-3'>
                        <Form.Text>Mostrar</Form.Text>
                        <Form.Check
                            className='mt-1'
                            type="checkbox"
                            id=""
                            label="Solo mis expedientes"
                            checked={selectedOnlyMine}
                            onChange={(event) => handleOnlyMine(event)}
                        />
                    </Form.Group>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseFilterModal}>
                        Cerrar
                    </Button>
                    <Button variant="primary" onClick={handleSearch}>
                        Confirmar
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}