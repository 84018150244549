import React, {useEffect} from 'react'
import WebFont from 'webfontloader';
import Moment from 'react-moment';
import moment from 'moment';
// eslint-disable-next-line
import {BrowserRouter as Router, Switch, Route, Link} from "react-router-dom";
import {Container, Row, Card, Button, Table, Dropdown, Badge, Modal, Form, ListGroup, Col} from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faArrowLeft, faArrowRight, faCheck, faCoins, faEye, faPen, faPlusCircle, faSchool, faSearch, faTrash, faUser} from '@fortawesome/free-solid-svg-icons'
import NavBar from "../navbar/Navbar";
import SubNavBarC from "../navbar/SubNavbarCustomers";
import SubNavBarD from "../navbar/SubNavbarDefendants";
import {get, put, del} from '../../../controllers/endpoints.controller';
import {getUserToken, logout} from '../../../controllers/user.controller';


export default function PersonDetails(props){

    const PAGE_TITLE = "Persona física";
    const PAGE_ICON = faUser;
    const [provinces, setProvinces] = React.useState([]);
    const [selectedProvince, setSelectedProvince] = React.useState("");
    const [genders, setGenders] = React.useState([]);
    const [selectedGenders, setSelectedGender] = React.useState(0);
    const [name, setName] = React.useState("");
    const [isEditing, setIsEditing] = React.useState(false);

    const [showDeletePersonModal, setShowDeletePersonModal] = React.useState(false);
    const handleCloseDeletePersonModal = () => { setShowDeletePersonModal(false); };
    const handleShowDeletePersonModal = () => {
        setShowDeletePersonModal(true);
    }

    const handleProvince = (event) => {
        setSelectedProvince(event.target.value);
    };

    const handleGender = (event) => {
        setSelectedGender(event.target.value);
    };

    const handleIsEditing = () => {
        setIsEditing(!isEditing)
    };

    useEffect(() => {
        WebFont.load({
            google: {
                families: ['Roboto:100,300,400']
            }
        });

        document.title = `Demo - ${PAGE_TITLE}`;

        getPerson();
        getProvinces();
        getGenders();
    }, []);

    const getProvinces = async function(){
        const req = await get('/others/provinces', getUserToken());
        const res = await req.json();

        if(req.status === 200){
            setProvinces(res);
        }else if(req.status === 403){
            logout();
        }else{
            alert(res.message);
        }
    }

    const getGenders = async function(){
        const req = await get('/others/genders', getUserToken());
        const res = await req.json();

        if(req.status === 200){
            setGenders(res);
        }else if(req.status === 403){
            logout();
        }else{
            alert(res.message);
        }
    }

    const getPersonId = function (){
        const params = new URLSearchParams(window.location.search);
        if(params.has('id')){
            return params.get('id');
        }else{
            return -1;
        }
    }

    const getPerson = async function(){
        const req = await get(`/${props.customers ? 'customers' : 'defendants'}/persons/byId/${getPersonId()}`, getUserToken());
        const res = await req.json();

        if(req.status === 200){
            processPerson(res)
            console.log(res)
        }else if(req.status === 403){
            logout();
        }else{
            console.log(res)
            alert(res.message);
        }
    }

    const processPerson = async function(data){
        setName(`${data.surname}, ${data.name}`)
        document.getElementById('textDNI').value = data.dni;
        document.getElementById('textName').value = data.name
        document.getElementById('textSurname').value = data.surname
        document.getElementById('textPhone').value = data.phone
        document.getElementById('textMobile').value = data.mobile
        document.getElementById('textEmail').value = data.email
        document.getElementById('textJob').value = data.placeWork
        document.getElementById('textAddress').value = data.address
        document.getElementById('textCity').value = data.city
        document.getElementById('textPostalCode').value = data.postalCode
        document.getElementById('textProvince').value = data.province
        if(data.PersonSex != null){
            document.getElementById('textGender').value = data.PersonSex.id
            setSelectedGender(data.PersonSex.id)
        }
        if(data.province != null){
            setSelectedProvince(data.province)
        }
    }

    const editPerson = async function(){
        const data = {
            id: getPersonId(),
            dni: document.getElementById('textDNI').value,
            personSexId: selectedGenders == 0 ? null : selectedGenders,
            name: document.getElementById('textName').value,
            surname: document.getElementById('textSurname').value,
            phone: document.getElementById('textPhone').value,
            mobile: document.getElementById('textMobile').value,
            email: document.getElementById('textEmail').value,
            isWorking: document.getElementById('textJob').value.length > 1 ? true : false,
            placeWork: document.getElementById('textJob').value,
            address: document.getElementById('textAddress').value,
            city: document.getElementById('textCity').value,
            province: selectedProvince,
            postalCode: document.getElementById('textPostalCode').value
        }
        
        const req = await put(`/${props.customers ? 'customers' : 'defendants'}/persons/`, getUserToken(), data);
        const res = await req.json();

        if(req.status === 201){
            setIsEditing(false)
        }else if(req.status === 403){
            logout();
        }else{
            console.log(res)
            alert(res.message);
        }
    }

    const deletePerson = async function () {
        const data = {
            personId: getPersonId(),
        }
        const req = await del(`/people/`, getUserToken(), data);
        const res = await req.json();

        if (req.status === 201) {
            window.location.href = `/${props.customers ? 'clientes/personas' : 'demandados/personas'}`
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    return(
        <div className='p-0' style={{fontFamily: 'Roboto'}}>
            <style>{'body {background-color: #EBEBEB !important;}'}</style>
            <NavBar/>
            {props.customers ? <SubNavBarC/> : <SubNavBarD/>}

            <div style={{height: 300, background: '#FAFAFA'}}>
                <Container>
                    <Row style={{paddingTop: 10}}>
                        <table className='w-75' style={{marginLeft: 'auto', marginRight: 'auto'}}>
                            <tr>
                                <td style={{fontSize: 45, fontWeight: 300}}>
                                    <p className='m-0' style={{color: '#A0A0A0', fontSize: 25, fontWeight: 300}}>{PAGE_TITLE}</p>
                                    <p className='m-0' style={{color: '#A0A0A0', fontSize: 45, fontWeight: 300}}>{name}</p>
                                </td>
                                <td style={{fontSize: 140, width: 150}}>
                                    <FontAwesomeIcon icon={PAGE_ICON} style={{fontSize: 140, color: "A0A0A0"}} />
                                </td>
                            </tr>
                        </table>
                    </Row>
                </Container>
            </div>

            <Container>
                <Row style={{marginTop: -70}}>
                    <Card className='cards content mb-5'>
                        <table className='content-options content'>
                            <tr>
                                <td className='content-options rows left'>
                                    <Link to={`/${props.customers ? 'clientes' : 'demandados'}/personas`} className='link-primary'>
                                        <FontAwesomeIcon icon={faArrowLeft} style={{marginRight: 8}} />Volver a Personas
                                    </Link>
                                    
                                    
                                </td>
                                <td className='content-options rows right'>
                                    <Button variant="warning" onClick={handleIsEditing}><FontAwesomeIcon icon={faPen} style={{marginRight: 8}} />Modificar</Button>
                                    <Button className='ms-2' variant="danger" onClick={handleShowDeletePersonModal}><FontAwesomeIcon icon={faTrash} style={{ marginRight: 8 }} />Eliminar</Button>
                                </td>
                            </tr>
                        </table>

                        <Container className='mt-4 mb-4'>
                        <Row>
                            <Col xs={6}>
                                <table className='w-100'>
                                    <tr>
                                        <td className='pt-2' style={{fontWeight: 'bold', width: 170}}>Nombre</td>
                                        <td className='pt-2'>
                                            <Form.Group>
                                                <Form.Control type="text" id="textName" readOnly={!isEditing} placeholder="" />
                                            </Form.Group>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='pt-2' style={{fontWeight: 'bold'}}>Apellido</td>
                                        <td className='pt-2'>
                                            <Form.Group>
                                                <Form.Control type="text" id="textSurname" readOnly={!isEditing} placeholder="" />
                                            </Form.Group>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='pt-2' style={{fontWeight: 'bold'}}>DNI</td>
                                        <td className='pt-2'>
                                            <Form.Group>
                                                <Form.Control type="text" id="textDNI" readOnly={!isEditing} placeholder="" />
                                            </Form.Group>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='pt-2' style={{fontWeight: 'bold'}}>Sexo</td>
                                        <td className='pt-2'>
                                            <Form.Group>
                                                <Form.Select className='cover content rows element' disabled={!isEditing} id="textGender" value={selectedGenders} onChange = {(event) => handleGender(event)}>
                                                    <option value="0">Seleccionar...</option>
                                                    {genders.map((p, i) => (
                                                        <option key={i} id={i} value={p.id}>{p.name}</option>
                                                    ))}
                                                </Form.Select>
                                            </Form.Group>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='pt-2' style={{fontWeight: 'bold'}}>Teléfono</td>
                                        <td className='pt-2'>
                                            <Form.Group>
                                                <Form.Control type="text" id="textPhone" readOnly={!isEditing} placeholder="" />
                                            </Form.Group>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='pt-2' style={{fontWeight: 'bold'}}>Celular</td>
                                        <td className='pt-2'>
                                            <Form.Group>
                                                <Form.Control type="text" id="textMobile" readOnly={!isEditing} placeholder="" />
                                            </Form.Group>
                                        </td>
                                    </tr>
                                    
                                </table>
                            </Col>
                            <Col xs={6}>
                            <table className='w-100'>
                                    
                                    <tr>
                                        <td className='pt-2' style={{fontWeight: 'bold'}}>Correo electrónico</td>
                                        <td className='pt-2'>
                                            <Form.Group>
                                                <Form.Control type="text" id="textEmail" readOnly={!isEditing} placeholder="" />
                                            </Form.Group>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='pt-2' style={{fontWeight: 'bold'}}>Trabajo</td>
                                        <td className='pt-2'>
                                            <Form.Group>
                                                <Form.Control type="text" id="textJob" readOnly={!isEditing} placeholder="" />
                                            </Form.Group>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='pt-2' style={{fontWeight: 'bold'}}>Dirección</td>
                                        <td className='pt-2'>
                                            <Form.Group>
                                                <Form.Control type="text" id="textAddress" readOnly={!isEditing} placeholder="" />
                                            </Form.Group>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='pt-2' style={{fontWeight: 'bold'}}>Localidad</td>
                                        <td className='pt-2'>
                                            <Form.Group>
                                                <Form.Control type="text" id="textCity" readOnly={!isEditing} placeholder="" />
                                            </Form.Group>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='pt-2' style={{fontWeight: 'bold'}}>Provincia</td>
                                        <td className='pt-2'>
                                            <Form.Group>
                                                <Form.Select className='cover content rows element' disabled={!isEditing} id="textProvince" value={selectedProvince}  onChange = {(event) => handleProvince(event)}>
                                                    <option value="0">Seleccionar...</option>
                                                    {provinces.map((p, i) => (
                                                        <option key={i} id={i} value={p.name}>{p.name}</option>
                                                    ))}
                                                </Form.Select>
                                            </Form.Group>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='pt-2' style={{fontWeight: 'bold'}}>Código postal</td>
                                        <td className='pt-2'>
                                            <Form.Group>
                                                <Form.Control type="text" id="textPostalCode" readOnly={!isEditing} placeholder="" />
                                            </Form.Group>
                                        </td>
                                    </tr>
                                </table>
                            </Col>
             

                            {isEditing ?
                                <div className='mt-5' style={{textAlign: 'right'}}>
                                <Button variant="success" onClick={editPerson}><FontAwesomeIcon icon={faCheck} style={{marginRight: 8}} />Confirmar cambios</Button>
                                </div>
                                
                            :
                                <div></div>
                            }
                            </Row>
                            
                            

                         
                            
                        </Container>
                    </Card>
                </Row>

                <Modal show={showDeletePersonModal} onHide={handleCloseDeletePersonModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>Eliminar</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        ¿Desea eliminar a la persona física?
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseDeletePersonModal}>
                            Cerrar
                        </Button>
                        <Button variant="danger" onClick={deletePerson}>
                            Eliminar
                        </Button>
                    </Modal.Footer>
                </Modal>
            </Container>
        </div>
    )
}