import React, {useEffect} from 'react'
// eslint-disable-next-line
import {BrowserRouter as Router, Switch, Route, Link} from "react-router-dom";
import {Container, Row, Card, Col, Badge, Dropdown, Button, Form, Modal} from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faCalendarAlt} from '@fortawesome/free-regular-svg-icons'
import {faCheck, faFilePdf, faEllipsisV, faCommentAlt, faArrowLeft, faPlusCircle, faGavel, faTrash, faSearch, faTasks, faCogs, faEye, faLink} from '@fortawesome/free-solid-svg-icons'
import '@fortawesome/fontawesome-free/css/all.css';
import NavBar from "../navbar/Navbar";
import WebFont from 'webfontloader';
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import bootstrapPlugin from '@fullcalendar/bootstrap';
import moment from 'moment'
import 'moment/locale/es-mx';
import {get, put, post} from '../../../controllers/endpoints.controller';
import {getUserToken, logout} from '../../../controllers/user.controller';
import { processDate, processDateCalendar, timeDue } from '../../../tools/tools';
import { params } from '../../../tools/constants';


export default function UserSettings(){

    moment.locale('es-mx');
    const PAGE_TITLE = "Configuración";
    const PAGE_ICON = faCogs;
    const [usersCol, setUsersCol] = React.useState([]);
    const [users, setUsers] = React.useState([]);
    const [actionCategories, setActionCategories] = React.useState([]);

    const [color, setColor] = React.useState("");  
    const [showNewActionCategoryModal, setShowNewActionCategoryModal] = React.useState(false);
    const handleCloseNewActionCategoryModal = () => {setShowNewActionCategoryModal(false);};
    const handleShowNewActionCategoryModal = () => {
        setShowNewActionCategoryModal(true);
    };
    const [integrations, setIntegrations] = React.useState([]);  
    
    useEffect(() => {
        WebFont.load({
            google: {
                families: ['Roboto:100,300,400']
            }
        });

        document.title = `Demo - ${PAGE_TITLE}`;
        getIntegrations();
    }, []);

    const getIntegrations = async function(){
        const req = await get(`/integrations`, getUserToken());
        const res = await req.json();

        if(req.status === 200){
            setIntegrations(res)
            console.log(res)
        }else if(req.status === 403){
            logout();
        }else{
            alert(res.message);
        }
    }

    const integrate = async function(url){
        const req = await get(`/integrations/${url}`, getUserToken());
        const res = await req.json();

        if(req.status === 200){
            window.location.href = res.url;
            //console.log(res)
        }else if(req.status === 403){
            logout();
        }else{
            alert(res.message);
        }
    }

    return(
        <div className='p-0' style={{fontFamily: 'Roboto'}}>
            <style>{'body {background-color: #EBEBEB !important;}'}</style>
            <NavBar/>

            <div style={{height: 300, background: '#FAFAFA'}}>
                <Container>
                    <Row style={{paddingTop: 10}}>
                        <table className='w-75' style={{marginLeft: 'auto', marginRight: 'auto'}}>
                            <tr>
                                <td style={{fontSize: 45, fontWeight: 300}}>
                                <span style={{color: '#A0A0A0'}}>{PAGE_TITLE}</span>
                                </td>
                                <td style={{fontSize: 140, width: 150}}>
                                    <FontAwesomeIcon icon={PAGE_ICON} style={{fontSize: 140, color: "A0A0A0"}} />
                                </td>
                            </tr>
                        </table>
                    </Row>
                </Container>
            </div>

            <Container>
                <Row style={{marginTop: -70}}>
                    <Card className='cards content mb-5'>
                        <table className='content-options content'>
                            <tr>
                                <td className='content-options rows left'>
                                    <span style={{fontSize: 18}}>Integraciones</span>
                                </td>
                                <td className='content-options rows right'>
                                    
                                </td>
                            </tr>
                        </table>

                        <p className='mt-4 mb-4 ps-4 pe-4'>
                            {integrations.map((c, i) => (
                                <Row>
                                <Col>
                                    {c.name}
                                </Col>
                                <Col style={{textAlign: 'right'}}>
                                    {c.IntegrationCredential != null ?
                                        <span>Integrado</span>
                                    :
                                        <Button variant="success" onClick={() => integrate('google_calendar')}><FontAwesomeIcon icon={faLink} style={{marginRight: 8}} />Integrar</Button>
                                    }
                                    
                                </Col>
                            </Row>
                            ))}
                            
                        </p>
                        
                    </Card>
                </Row>      
            </Container>

            
        </div>
    )
}