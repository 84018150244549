import React, {useEffect} from 'react'
import WebFont from 'webfontloader';
// eslint-disable-next-line
import {BrowserRouter as Router, Switch, Route, Link} from "react-router-dom";
import {Container, Row, Card, Button, Form, Dropdown, DropdownButton, Badge} from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faPlusCircle, faUsers, faEye, faUser, faSearch, faBusinessTime, faIndustry} from '@fortawesome/free-solid-svg-icons'
import NavBar from "../navbar/Navbar";
import SubNavBarC from "../navbar/SubNavbarCustomers";
import SubNavBarD from "../navbar/SubNavbarDefendants";
import {get, post} from '../../../controllers/endpoints.controller';
import {getUserToken, logout} from '../../../controllers/user.controller';

export default function Business(props){

    const PAGE_TITLE = "Personas jurídicas";
    const PAGE_ICON = faIndustry;
    const [tableColumns, setTableColumns] = React.useState([]);
    const [tableRows, setTableRows] = React.useState([]);
    
    useEffect(() => {
        WebFont.load({
            google: {
                families: ['Roboto:100,300,400']
            }
        });

        document.title = `Demo - ${PAGE_TITLE}`;
        getBusiness();
    }, []);

    const handleSearch = async function(event){
        const chain = {
            chain: event.target.value
        };
        const req = await post(`/${props.customers ? 'customers' : 'defendants'}/business/search`, getUserToken(), chain);
        const res = await req.json();

        if(req.status === 200){
            console.log(res)
            processBusiness(res)
        }else if(req.status === 403){
            logout();
        }else{
            alert(res.message);
            console.log(res)
        }
    }


    const getBusiness = async function(status){
        const req = await get(`/${props.customers ? 'customers' : 'defendants'}/business/`, getUserToken());
        const res = await req.json();

        if(req.status === 200){
            processBusiness(res)
        }else if(req.status === 403){
            logout();
        }else{
            alert(res.message);
        }
    }

    const processBusiness = async function(data){
        let result = [];
        for(let r of data){
            //r.debts = <div><Badge bg='' className='content-table status' style={{backgroundColor: `${status['Activo']}`}}>{r.debtStatus.active}</Badge><Badge bg='' className='content-table status ms-2' style={{backgroundColor: `${status['En juicio']}`}}>{r.debtStatus.onCourt}</Badge><Badge bg='' className='content-table status ms-2' style={{backgroundColor: `${status['Cerrado']}`}}>{r.debtStatus.closed}</Badge></div>
            r.action = <Link to={`/${props.customers ? 'clientes' : 'demandados'}/empresas/detalles/?id=${r.id}`}><Button className='' variant="primary"><FontAwesomeIcon icon={faEye} /></Button></Link>;
            r.code = <Badge bg='' className='me-2 content-table status' style={{backgroundColor: `${r.color}`}}>{r.code}</Badge>;
            result.push(r);
        }
        setTableRows(result);
        setupTable();
    }

    const setupTable = function(){
        const cols = ['Razón social', 'Código', 'Orden', 'Localidad', 'Detalle'];
        setTableColumns(cols);
    };

    return(
        <div className='p-0' style={{fontFamily: 'Roboto'}}>
            <style>{'body {background-color: #EBEBEB !important;}'}</style>
            <NavBar/>
            {props.customers ? <SubNavBarC/> : <SubNavBarD/>}

            <div style={{height: 300, background: '#FAFAFA'}}>
                <Container>
                    <Row style={{paddingTop: 10}}>
                        <table className='w-75' style={{marginLeft: 'auto', marginRight: 'auto'}}>
                            <tr>
                                <td style={{fontSize: 45, fontWeight: 300}}>
                                    <p className='m-0' style={{color: '#A0A0A0', fontSize: 25, fontWeight: 300}}>Contrarios</p>
                                    <p className='m-0' style={{color: '#A0A0A0', fontSize: 45, fontWeight: 300}}>{PAGE_TITLE}</p>
                                </td>
                                <td style={{fontSize: 140, width: 150}}>
                                    <FontAwesomeIcon icon={PAGE_ICON} style={{fontSize: 140, color: "A0A0A0"}} />
                                </td>
                            </tr>
                        </table>
                    </Row>
                </Container>
            </div>

            <Container>
                <Row style={{marginTop: -70}}>
                    <Card className='cards content mb-5'>
                        <table className='content-options content'>
                            <tr>
                                <td className='content-options rows left'>
                                    <FontAwesomeIcon icon={faSearch}></FontAwesomeIcon><Form.Control className='ms-2' type="text" id="textFee" style={{width: 250, display: 'inline'}} placeholder="" onChange = {(event) => handleSearch(event)} />
                                </td>
                                <td className='content-options rows right'>
                                    <Link to={`/${props.customers ? 'clientes' : 'demandados'}/empresas/nueva`}>
                                        <Button variant="success"><FontAwesomeIcon icon={faPlusCircle} style={{marginRight: 8}} />Nueva persona jurídica</Button>
                                    </Link>
                                    
                                </td>
                            </tr>
                        </table>

                        <table striped hover className='mb-3'>
                            <thead>
                                <tr>
                                    {tableColumns.map((c, i) => (
                                        <th key={i} className='content-table head'>{c}</th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {tableRows.map((r, i) => (
                                    <tr key={i} >
                                        <td className='content-table rows middle align-middle ps-4'>{r.legalName}</td>
                                        <td className='content-table rows middle align-middle' style={{width: 100}}>{r.code}</td>
                                        <td className='content-table rows middle align-middle'>{r.educationalNetwork != null ? r.educationalNetwork.name : "---"}</td>
                                        <td className='content-table rows middle align-middle'>{r.city ? r.city : "---"}</td>
                                        <td className='content-table rows middle align-middle' style={{width: 150}}>{r.action}</td>
                                    </tr>
                                ))}
                                
                            </tbody>
                        </table>
                    </Card>
                </Row>
            </Container>
        </div>
    )
}