import React, { useEffect } from 'react'
import WebFont from 'webfontloader';
import Moment from 'react-moment';
import moment from 'moment';
// eslint-disable-next-line
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { Container, Row, Card, Button, Table, Dropdown, Badge, Modal, Form, ListGroup, Col } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faArrowRight, faCoins, faEye, faPlusCircle, faSchool, faSearch, faUser } from '@fortawesome/free-solid-svg-icons'
import NavBar from "../navbar/Navbar";
import SubNavBarC from "../navbar/SubNavbarCustomers";
import SubNavBarD from "../navbar/SubNavbarDefendants";
import { get, post } from '../../../controllers/endpoints.controller';
import { getUserToken, logout } from '../../../controllers/user.controller';


export default function NewPerson(props) {

    const PAGE_TITLE = "Nueva persona física";
    const PAGE_ICON = faUser;
    const [provinces, setProvinces] = React.useState([]);
    const [selectedProvince, setSelectedProvince] = React.useState("");
    const [genders, setGenders] = React.useState([]);
    const [selectedGenders, setSelectedGender] = React.useState(0);

    const handleProvince = (event) => {
        setSelectedProvince(event.target.value);
    };

    const handleGender = (event) => {
        setSelectedGender(event.target.value);
    };

    useEffect(() => {
        WebFont.load({
            google: {
                families: ['Roboto:100,300,400']
            }
        });

        document.title = `Demo - ${PAGE_TITLE}`;

        getProvinces();
        getGenders();
    }, []);

    const getProvinces = async function () {
        const req = await get('/others/provinces', getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            setProvinces(res);
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const getGenders = async function () {
        const req = await get('/others/genders', getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            setGenders(res);
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const createPerson = async function () {
        const data = {
            dni: document.getElementById('textDNI').value,
            personTypeId: null,
            personSexId: selectedGenders,
            familyId: null,
            name: document.getElementById('textName').value,
            surname: document.getElementById('textSurname').value,
            phone: document.getElementById('textPhone').value,
            mobile: document.getElementById('textMobile').value,
            email: document.getElementById('textEmail').value,
            isWorking: document.getElementById('textJob').value.length > 1 ? true : false,
            placeWork: document.getElementById('textJob').value,
            address: document.getElementById('textAddress').value,
            city: document.getElementById('textCity').value,
            province: selectedProvince,
            postalCode: document.getElementById('textPostalCode').value
        }

        const req = await post(`/${props.customers ? 'customers' : 'defendants'}/persons`, getUserToken(), data);
        const res = await req.json();

        if (req.status === 201) {
            window.location.href = `/${props.customers ? 'clientes' : 'demandados'}/personas`;
        } else if (req.status === 403) {
            logout();
        } else {
            console.log(res)
            alert(res.message);
        }
    }



    return (
        <div className='p-0' style={{ fontFamily: 'Roboto' }}>
            <style>{'body {background-color: #EBEBEB !important;}'}</style>
            <NavBar />
            {props.customers ? <SubNavBarC /> : <SubNavBarD />}

            <div style={{ height: 300, background: '#FAFAFA' }}>
                <Container>
                    <Row style={{ paddingTop: 10 }}>
                        <table className='w-75' style={{ marginLeft: 'auto', marginRight: 'auto' }}>
                            <tr>
                                <td style={{ fontSize: 45, fontWeight: 300 }}>
                                    <span style={{ color: '#A0A0A0' }}>{PAGE_TITLE}</span>
                                </td>
                                <td style={{ fontSize: 140, width: 150 }}>
                                    <FontAwesomeIcon icon={PAGE_ICON} style={{ fontSize: 140, color: "A0A0A0" }} />
                                </td>
                            </tr>
                        </table>
                    </Row>
                </Container>
            </div>

            <Container>
                <Row style={{ marginTop: -70 }}>
                    <Card className='cards content mb-5'>
                        <table className='content-options content'>
                            <tr>
                                <td className='content-options rows left'>
                                    <Link to={`/${props.customers ? 'clientes' : 'demandados'}/personas`} className='link-primary'>
                                        <FontAwesomeIcon icon={faArrowLeft} style={{ marginRight: 8 }} />Volver a Personas
                                    </Link>


                                </td>
                                <td className='content-options rows right'>

                                </td>
                            </tr>
                        </table>

                        <Container className='mt-4 mb-4'>
                            <Row>
                                <Col xs={6}>
                                    <table className='w-100'>
                                        <tr>
                                            <td className='pt-2' style={{ fontWeight: 'bold', width: 170 }}>Nombre</td>
                                            <td className='pt-2'>
                                                <Form.Group>
                                                    <Form.Control type="text" id="textName" placeholder="" />
                                                </Form.Group>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className='pt-2' style={{ fontWeight: 'bold' }}>Apellido</td>
                                            <td className='pt-2'>
                                                <Form.Group>
                                                    <Form.Control type="text" id="textSurname" placeholder="" />
                                                </Form.Group>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className='pt-2' style={{ fontWeight: 'bold' }}>DNI</td>
                                            <td className='pt-2'>
                                                <Form.Group>
                                                    <Form.Control type="text" id="textDNI" placeholder="" />
                                                </Form.Group>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className='pt-2' style={{ fontWeight: 'bold' }}>Sexo</td>
                                            <td className='pt-2'>
                                                <Form.Group>
                                                    <Form.Select className='cover content rows element' id="" onChange={(event) => handleGender(event)}>
                                                        <option value="0">Seleccionar...</option>
                                                        {genders.map((p, i) => (
                                                            <option key={i} id={i} value={p.id}>{p.name}</option>
                                                        ))}
                                                    </Form.Select>
                                                </Form.Group>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className='pt-2' style={{ fontWeight: 'bold' }}>Teléfono</td>
                                            <td className='pt-2'>
                                                <Form.Group>
                                                    <Form.Control type="text" id="textPhone" placeholder="" />
                                                </Form.Group>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className='pt-2' style={{ fontWeight: 'bold' }}>Celular</td>
                                            <td className='pt-2'>
                                                <Form.Group>
                                                    <Form.Control type="text" id="textMobile" placeholder="" />
                                                </Form.Group>
                                            </td>
                                        </tr>

                                    </table>
                                </Col>
                                <Col xs={6}>
                                    <table className='w-100'>

                                        <tr>
                                            <td className='pt-2' style={{ fontWeight: 'bold' }}>Correo electrónico</td>
                                            <td className='pt-2'>
                                                <Form.Group>
                                                    <Form.Control type="text" id="textEmail" placeholder="" />
                                                </Form.Group>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className='pt-2' style={{ fontWeight: 'bold' }}>Trabajo</td>
                                            <td className='pt-2'>
                                                <Form.Group>
                                                    <Form.Control type="text" id="textJob" placeholder="" />
                                                </Form.Group>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className='pt-2' style={{ fontWeight: 'bold' }}>Dirección</td>
                                            <td className='pt-2'>
                                                <Form.Group>
                                                    <Form.Control type="text" id="textAddress" placeholder="" />
                                                </Form.Group>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className='pt-2' style={{ fontWeight: 'bold' }}>Localidad</td>
                                            <td className='pt-2'>
                                                <Form.Group>
                                                    <Form.Control type="text" id="textCity" placeholder="" />
                                                </Form.Group>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className='pt-2' style={{ fontWeight: 'bold' }}>Provincia</td>
                                            <td className='pt-2'>
                                                <Form.Group>
                                                    <Form.Select className='cover content rows element' id="" onChange={(event) => handleProvince(event)}>
                                                        <option value="0">Seleccionar...</option>
                                                        {provinces.map((p, i) => (
                                                            <option key={i} id={i} value={p.name}>{p.name}</option>
                                                        ))}
                                                    </Form.Select>
                                                </Form.Group>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className='pt-2' style={{ fontWeight: 'bold' }}>Código postal</td>
                                            <td className='pt-2'>
                                                <Form.Group>
                                                    <Form.Control type="text" id="textPostalCode" placeholder="" />
                                                </Form.Group>
                                            </td>
                                        </tr>
                                    </table>
                                </Col>
                            </Row>
                            <Form>


                            </Form>

                            <div className='mt-5' style={{ textAlign: 'right' }}>
                                <Button variant="success" onClick={createPerson}>Siguiente<FontAwesomeIcon icon={faArrowRight} style={{ marginLeft: 8 }} /></Button>
                            </div>

                        </Container>
                    </Card>
                </Row>
            </Container>
        </div>
    )
}