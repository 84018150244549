import React, { useEffect } from 'react'
import WebFont from 'webfontloader';
import Moment from 'react-moment';
import moment from 'moment';
// eslint-disable-next-line
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { Container, Row, Card, Button, Table, Dropdown, Badge, Modal, Form, Col } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faArrowRight, faCoins, faEye, faGavel, faPlusCircle, faSchool, faSearch } from '@fortawesome/free-solid-svg-icons'
import NavBar from "../navbar/Navbar";

export default function NewCourtFileStep2() {

    const PAGE_TITLE = "Nuevo expediente judicial";
    const PAGE_ICON = faGavel;

    const [step1Data, setStep1Data] = React.useState({});
    const [customer, setCustomer] = React.useState({});
    const [defendants, setDefendants] = React.useState([]);
    const location = useLocation();
    const step1 = location.state;

    const [cfName, setCfName] = React.useState("");
    const [cfNumber, setCfNumber] = React.useState("");
    const [cfCourt, setCfCourt] = React.useState("");

    const handleCfName = (event) => {
        setCfName(event.target.value);
    };

    const handleCfNumber = (event) => {
        setCfNumber(event.target.value);
    };

    const handleCfCourt = (event) => {
        setCfCourt(event.target.value);
    };

    useEffect(() => {
        WebFont.load({
            google: {
                families: ['Roboto:100,300,400']
            }
        });

        document.title = `Demo - ${PAGE_TITLE}`;
        processStep1Data(step1)
    }, []);

    const processStep1Data = (data) => {
        let d = [];
        if (data.defendant1 != null) { d.push(data.defendant1) }
        if (data.defendant2 != null) { d.push(data.defendant2) }
        if (data.defendant3 != null) { d.push(data.defendant3) }

        const sd = {
            userId: data.userId,
            customer: data.customer,
            defendants: d,
            defendantLawer: data.defendantLawer,
            date: data.date
        }

        setStep1Data(sd)

        //getCustomerData(sd.isBusiness, sd.customer.id)
        setCustomer(data.customer)
        setDefendants(d);
    }

    return (
        <div className='p-0' style={{ fontFamily: 'Roboto' }}>
            <style>{'body {background-color: #EBEBEB !important;}'}</style>
            <NavBar />

            <div style={{ height: 300, background: '#FAFAFA' }}>
                <Container>
                    <Row style={{ paddingTop: 10 }}>
                        <table className='w-75' style={{ marginLeft: 'auto', marginRight: 'auto' }}>
                            <tr>
                                <td style={{ fontSize: 45, fontWeight: 300 }}>
                                    <span style={{ color: '#A0A0A0' }}>{PAGE_TITLE}</span>
                                </td>
                                <td style={{ fontSize: 140, width: 150 }}>
                                    <FontAwesomeIcon icon={PAGE_ICON} style={{ fontSize: 140, color: "A0A0A0" }} />
                                </td>
                            </tr>
                        </table>
                    </Row>
                </Container>
            </div>

            <Container>
                <Row style={{ marginTop: -70 }}>
                    <Card className='cards content mb-5'>
                        <table className='content-options content'>
                            <tr>
                                <td className='content-options rows left'>
                                    <Link to={'/expedientes'} className='link-primary'>
                                        <FontAwesomeIcon icon={faArrowLeft} style={{ marginRight: 8 }} />Volver a Judicial
                                    </Link>
                                    <span className='ms-3' style={{ color: '#A0A0A0' }}>|</span><span className='ms-3'>Datos generales del expediente</span>

                                </td>
                                <td className='content-options rows right'>

                                </td>
                            </tr>
                        </table>

                        <Container className='mt-4 mb-4'>
                            <Row>
                                <Col>
                                    <Row className='mt-4 mb-0'>
                                        <Col>
                                            <p className='column-title'>Cliente</p>
                                        </Col>
                                    </Row>
                                    <Row className='mb-4' style={{ marginTop: -16 }}>
                                        <Col>
                                            <div className='column-content'>
                                                <Form>
                                                    <Form.Group>
                                                        <Form.Control className='' disabled type="text" id="textClient" placeholder="" value={customer.isBusiness == true ? (`${customer.cuit ? `CUIT ${customer.cuit} - ` : ""}${customer.legalName}`) : `${customer.dni > 1 ? `DNI ${customer.dni} - ` : ""}${customer.surname ? customer.surname.toUpperCase() : ""}, ${customer.name}`} />
                                                    </Form.Group>
                                                </Form>
                                            </div>
                                        </Col>
                                    </Row>

                                    <Row className='mt-4 mb-0'>
                                        <Col>
                                            <p className='column-title'>Contrarios</p>
                                        </Col>
                                    </Row>
                                    <Row className='mb-4' style={{ marginTop: -16 }}>
                                        <Col>
                                            <div className='column-content'>
                                                <Form>
                                                    <Form.Group>
                                                        {defendants.map((d, i) => (
                                                            <Form.Control className={i > 0 ? 'mt-2' : ''} disabled type="text" id="" placeholder="" value={d.isBusiness == true ? (`${d.cuit ? `CUIT ${d.cuit} - ` : ""}${d.legalName}`) : `${d.dni > 1 ? `DNI ${d.dni} - ` : ""}${d.surname ? d.surname.toUpperCase() : ""}, ${d.name}`} />
                                                        ))}
                                                    </Form.Group>
                                                </Form>
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col>
                                    <Row className='mt-4 mb-0'>
                                        <Col>
                                            <p className='column-title'>Datos del expediente</p>
                                        </Col>
                                    </Row>
                                    <Row className='mb-4' style={{ marginTop: -16 }}>
                                        <Col>
                                            <div className='column-content'>
                                                <Form>
                                                    <table className='w-100' >
                                                        <tr>
                                                            <td style={{ width: 170, fontWeight: 'bold' }}>Carátula</td>
                                                            <td className=''>
                                                                <Form.Group>
                                                                    <Form.Control className='' type="text" id="textName" placeholder="" onChange={(event) => handleCfName(event)} />
                                                                </Form.Group>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className='pt-2' style={{ width: 170, fontWeight: 'bold' }}>Nº de expediente</td>
                                                            <td className='pt-2'>
                                                                <Form.Group>
                                                                    <Form.Control className='' type="text" id="textCourtFileNumber" placeholder="" onChange={(event) => handleCfNumber(event)} />
                                                                </Form.Group>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className='pt-2' style={{ fontWeight: 'bold' }}>Fuero</td>
                                                            <td className='pt-2'>
                                                                <Form.Group>
                                                                    <Form.Control className='' type="text" id="textCourt" placeholder="" onChange={(event) => handleCfCourt(event)} />
                                                                </Form.Group>
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </Form>
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>








                            <div className='mt-5' style={{ textAlign: 'right' }}>
                                <Link to={`/judicial/nueva/3`} state={
                                    {
                                        name: cfName,
                                        number: cfNumber,
                                        court: cfCourt,
                                        defendants: defendants,
                                        customer: customer,
                                        userId: step1Data.userId,
                                        defendantLawer: step1Data.defendantLawer,
                                        date: step1Data.date
                                    }
                                }>
                                    <Button variant="success">Crear expediente<FontAwesomeIcon icon={faArrowRight} style={{ marginLeft: 8 }} /></Button>
                                </Link>
                            </div>

                        </Container>
                    </Card>
                </Row>
            </Container>
        </div>
    )
}